import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../../styles.module.scss'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Button } from 'components/Form/components/Button'
import { IEmployeeStundenzettel } from 'contracts/employees/interfaces/IEmployeeStundenzettel'
import { hermes } from '@byll/hermes'
import { Message } from 'components/Message'
import { EmployeeStundenzettel } from './components/EmployeeStundenzettel'
import { box } from 'services/box'
import { RoundIcon } from 'components/RoundIcon'
import { IEmployeeQualification } from 'contracts/employees/interfaces/IEmployeeQualification'
import { EmployeeQualification } from './components/EmployeeQualification'
import { EmployeeQualificationDialog } from './components/EmployeeQualificationDialog'
import { IQualification } from 'contracts/workload/interfaces/IQualification'
import { IEmployeeProfile } from 'contracts/employees/interfaces/IEmployeeProfile'
import { dayjs } from 'helpers/dayjs'

interface Props {
  employee: IEmployeeSearchResult
  month: string
}

@observer
export class EmployeeDialogOverviewTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loadingPlan = false
  @observable private loadingReal = false
  @observable private showArchive = false
  @observable private stundenzettelError: string | null = null
  @observable private qualificationError: string | null = null
  @observable private stundenzettel: IEmployeeStundenzettel[] | null = null
  @observable private employeeQualifications: IEmployeeQualification[] | null = null
  @observable private qualifications: IQualification[] | null = null
  @observable private categories: any[] | null = null
  @observable private profile: IEmployeeProfile | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.loadProfile()
    if (this.context.permissions.user_hrStundenzettelList > 0) {
      this.loadStundenzettel()
    }
    if (this.context.permissions.user_hrQualifications > 0) {
      this.loadEmployeeQualifications()
      this.loadQualifications()
      this.loadCategories()
    }
  }

  @action private toggleArchive = () => {
    this.showArchive = !this.showArchive
  }

  private loadProfile = async () => {
    try {
      const profile = await hermes.getOnceNew<IEmployeeProfile>(
        `/api/${this.context.instance.id}/employeeProfiles/${this.props.employee.id}`,
      )
      runInAction(() => (this.profile = profile))
    } catch (_e) {
      /* Ignore */
    }
  }

  private loadStundenzettel = async () => {
    try {
      const stundenzettel = await hermes.indexOnceNew<IEmployeeStundenzettel>(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/stundenzettel`,
      )
      runInAction(() => (this.stundenzettel = stundenzettel))
    } catch (_e) {
      runInAction(
        () =>
          (this.stundenzettelError = 'Die Stundenzettel konnten nicht geladen werden.'),
      )
    }
  }

  private loadEmployeeQualifications = async () => {
    try {
      const qualifications = await hermes.indexOnceNew<IEmployeeQualification>(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/qualifications`,
      )
      runInAction(() => (this.employeeQualifications = qualifications))
    } catch (_e) {
      runInAction(
        () =>
          (this.qualificationError = 'Die Qualifikationen konnten nicht geladen werden.'),
      )
    }
  }

  private loadQualifications = async () => {
    try {
      const qualifications = await hermes.indexOnceNew<any>(
        `/api/${this.context.instance.id}/workload/qualifications`,
      )
      runInAction(() => (this.qualifications = qualifications))
    } catch (_e) {
      runInAction(
        () =>
          (this.qualificationError = 'Die Qualifikationen konnten nicht geladen werden.'),
      )
    }
  }

  private loadCategories = async () => {
    try {
      const categories = await hermes.indexOnceNew<any>(
        `/api/${this.context.instance.id}/workload/qualificationCategories`,
      )
      runInAction(() => (this.categories = categories))
    } catch (_e) {
      runInAction(
        () =>
          (this.qualificationError =
            'Die Qualifikationskategorien konnten nicht geladen werden.'),
      )
    }
  }

  private downloadPlan = () => this.download('arbeitsplan')
  private downloadReal = () => this.download('realzeiten')

  private download = async (type: 'arbeitsplan' | 'realzeiten') => {
    if (this.loadingPlan || this.loadingReal) {
      return
    }

    if (type === 'realzeiten') {
      runInAction(() => (this.loadingReal = true))
      await createAndDownloadReport('stundenzettelByEmployee', this.context.instance.id, {
        month: this.props.month,
        employeeId: this.props.employee.id,
        userId: this.context.user.id,
      })
      runInAction(() => (this.loadingReal = false))
      return
    }

    runInAction(() => (this.loadingPlan = true))
    await createAndDownloadReport(
      'pdfArbeitsplan',
      this.context.instance.id,
      {
        month: this.props.month,
        userId: this.props.employee.id,
        initiatorId: this.context.user.id,
      },
      'Arbeitsplan.pdf',
    )
    runInAction(() => (this.loadingPlan = false))
  }

  private deleteStundenzettel = async (id: string) => {
    if (
      !(await box.alert(
        'Stundenzettel löschen?',
        'Möchten Sie den Stundenzettel wirklich unwiderruflich löschen?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/stundenzettel/${id}`,
      )
      runInAction(() => {
        this.stundenzettel = this.stundenzettel?.filter((s) => s.id !== id) || []
      })
    } catch (_e) {
      runInAction(
        () =>
          (this.stundenzettelError = 'Der Stundenzettel konnte nicht gelöscht werden.'),
      )
    }
  }

  @action private addQualification = async () => {
    // Should not happen, just for typescript
    if (!this.employeeQualifications) {
      return
    }
    const qual = {
      id: '',
      userId: this.props.employee.id,
      qualificationId: '0',
      categoryId: '0',
      startDate: null,
      endDate: null,
      trainingStartDate: null,
      trainingEndDate: null,
      notes: '',
      documentId: null,
    }
    const promise = box.custom(
      <EmployeeQualificationDialog
        qualification={qual}
        onClose={(id) => promise.close(id)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
    const res = await promise
    if (res) {
      this.loadEmployeeQualifications()
    }
  }

  private editQualification = async (qualification: IEmployeeQualification) => {
    const promise = box.custom(
      <EmployeeQualificationDialog
        qualification={qualification}
        onClose={(id) => promise.close(id)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
    const res = await promise
    if (res) {
      this.loadEmployeeQualifications()
    }
  }

  @action private deleteQualification = async (id: string) => {
    // Should not happen, just for typescript
    if (!this.employeeQualifications) {
      return
    }
    if (
      !(await box.alert(' Löschen', 'Möchten Sie das  wirklich unwiderruflich löschen?', {
        confirm: 'Ja',
        cancel: 'Abbrechen',
      }))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/qualifications/${id}`,
      )
      for (let i = this.employeeQualifications.length - 1; i >= 0; i--) {
        if (this.employeeQualifications[i].id !== id) {
          continue
        }
        runInAction(() => this.employeeQualifications!.splice(i, 1))
        break
      }
    } catch (_e) {
      runInAction(
        () =>
          (this.qualificationError = 'Die Qualifikation konnte nicht gelöscht werden.'),
      )
    }
  }

  private renderQualifications = () => {
    const elements: (JSX.Element | null)[] = []
    if (!this.employeeQualifications) {
      return elements
    }
    for (const category of this.categories || []) {
      if (category.id === '1') {
        continue
      }
      if (!this.employeeQualifications.some((q) => q.categoryId === category.id)) {
        continue
      }
      elements.push(
        <div key={category.id}>
          <div className='f4 truncate mb-3'>{category.label}</div>
          {this.employeeQualifications
            .filter((q) => q.categoryId === category.id)
            .map((q) => this.mapQualification(q))}
        </div>,
      )
    }
    return elements
  }

  private renderArchive = () => {
    const elements: (JSX.Element | null)[] = []

    const archived = this.employeeQualifications?.filter((q) => q.categoryId === '1')
    if (!archived || archived.length === 0) {
      return null
    }
    for (const qualification of archived) {
      elements.push(this.mapQualification(qualification))
    }
    return elements
  }

  private mapQualification = (qualification: IEmployeeQualification) => {
    if (!this.qualifications) {
      return null
    }
    let qual = this.qualifications.find((q) => q.id === qualification.qualificationId)
    if (qualification.qualificationId === '0') {
      qual = { label: 'Bitte wählen...', short: '' } as IQualification
    }
    if (!qual) {
      return null
    }
    return (
      <EmployeeQualification
        key={qualification.id}
        qualification={qualification}
        rQualification={qual}
        onEdit={this.editQualification}
        onDelete={this.deleteQualification}
      />
    )
  }

  render() {
    const { employee } = this.props
    return (
      <div>
        {/* User image */}
        <img
          src={getUserImageSrc(
            this.context.instance.id,
            employee.imageId, // user.imageId,
            employee.sex, // user.sex,
            'portrait',
          )}
          className={styles.portrait}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='flex flex-col gap-6 p-6'>
          <div className='mr-[238px] bg-white rounded-md shadow-md p-4'>
            <div className='text-lg text-gray-600'>Kurzprofil</div>
            <div className='mt-4'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='truncate'>
                  <div className='text-sm text-gray-500'>Handynummer</div>
                  <div className='text-base text-gray-800'>
                    {this.profile?.mobile || '-'}
                  </div>
                </div>
                <div className='col-span-2 truncate'>
                  <div className='text-sm text-gray-500'>E-Mail</div>
                  <div className='text-base text-gray-800'>
                    {this.profile?.email || '-'}
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-3 gap-4 mt-4'>
                <div className='truncate'>
                  <div className='text-sm text-gray-500'>Geburtsdatum</div>
                  <div className='text-base text-gray-800'>
                    {this.profile?.dateOfBirth
                      ? dayjs(this.profile.dateOfBirth).format('DD.MM.YYYY')
                      : '-'}
                  </div>
                </div>
                <div className='col-span-2 truncate'>
                  <div className='text-sm text-gray-500'>Wohnhaft</div>
                  <div className='text-base text-gray-800'>
                    {this.profile?.zip || this.profile?.city
                      ? `${this.profile.zip} ${this.profile.city}`
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-4 flex gap-4'>
              <Button onClick={this.downloadPlan} loading={this.loadingPlan}>
                Arbeitsplan
              </Button>
              <Button onClick={this.downloadReal} loading={this.loadingReal}>
                Realzeiten
              </Button>
            </div>
          </div>
          {this.context.permissions.user_hrQualifications > 0 && (
            <div className='bg-white shadow-md rounded-md p-4'>
              <div className='flex'>
                <div className='flex-auto text-lg text-gray-600 truncate'>
                  Qualifikationen
                </div>
                <div className='flex-content'>
                  {this.context.permissions.user_hrQualifications === 2 && (
                    <RoundIcon icon='fa fa-plus' onClick={this.addQualification} />
                  )}
                </div>
              </div>

              {this.qualificationError && (
                <Message className='mt-2 mb-4' color='danger'>
                  {this.qualificationError}
                </Message>
              )}
              <div className='text-gray-900 mt-2 text-base'>
                {this.renderQualifications()}
                {this.employeeQualifications &&
                  this.employeeQualifications.length === 0 && (
                    <div className='text-gray-400'>Keine Qualifikationen vorhanden.</div>
                  )}
                {this.employeeQualifications &&
                  this.employeeQualifications.filter((q) => q.categoryId === '1').length >
                    0 && (
                    <button
                      className='text-blue-400 hover:underline'
                      color='secondary'
                      onClick={this.toggleArchive}
                    >
                      Archiv {this.showArchive ? 'ausblenden' : 'anzeigen'}
                    </button>
                  )}
                {this.showArchive && this.renderArchive()}
              </div>
            </div>
          )}
          {this.context.permissions.user_hrStundenzettelList > 0 && (
            <div className='bg-white shadow-md rounded-md p-4'>
              <div className='text-lg text-gray-600 mb-2'>Stundenzettel</div>
              {this.stundenzettelError && (
                <Message className='mb-4' color='danger'>
                  {this.stundenzettelError}
                </Message>
              )}
              {this.stundenzettel &&
                this.stundenzettel.length > 0 &&
                this.stundenzettel?.map((s) => (
                  <EmployeeStundenzettel
                    key={s.id}
                    stundenzettel={s}
                    onDelete={this.deleteStundenzettel}
                  />
                ))}
              {this.stundenzettel?.length === 0 && (
                <div className='text-gray-400'>Keine Stundenzettel vorhanden.</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
