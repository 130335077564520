import { Menu, Transition } from '@headlessui/react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { makeObservable, observable, runInAction } from 'mobx'
import { dayjs } from 'helpers/dayjs'
import { RoundIcon } from 'components/RoundIcon'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { Collection } from '@byll/hermes'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'

interface Props {
  month: string
  reports: Collection<IEmployeeReportDefinition>
  selected: Set<string> // Employee IDs
  filter: IEmployeeSearchResultsFilter
  employees: Collection<IEmployeeSearchResult>
  navigate: (path: string) => void
}

@observer
export class DownloadDropdownButton extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private downloading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  createStundenzettelByCompany = async () => {
    if (this.downloading) {
      return
    }
    if (!this.props.filter.companyId) {
      alert(
        'Bitte wählen Sie eine Firma aus, für die der Stundenzettel erstellt werden soll.',
      )
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('stundenzettelByCompany', this.context.instance.id, {
      month: this.props.month,
      companyId: this.props.filter.companyId,
    })
    runInAction(() => (this.downloading = false))
  }

  createUserCardList = async () => {
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('userCardList', this.context.instance.id, {
      companyId: this.props.filter.companyId || null,
    })
    runInAction(() => (this.downloading = false))
  }

  createQualificationList = async () => {
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('employeeQualificationList', this.context.instance.id, {
      month: this.props.month,
      companyId: this.props.filter.companyId || null,
      scope: this.props.filter.scope,
    })
    runInAction(() => (this.downloading = false))
  }

  createVacationListTill = async () => {
    if (!this.props.filter.companyId) {
      alert('Bitte wählen Sie eine Firma für die Urlaubsliste aus.')
      return
    }
    const response: string =
      prompt(
        'Bis zu welchem Datum soll der geplante Urlaub berücksichtigt werden?',
        dayjs().format('DD.MM.YYYY'),
      ) || ''
    const till = dayjs(response, ['D.M.YYYY', 'D.M.YY'])
    if (!till.isValid()) {
      alert(
        'Das angegebene Datum ist nicht gültig. Bitte geben Sie das Datum im Format TT.MM.JJJJ an.',
      )
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('employeeVacationList', this.context.instance.id, {
      month: this.props.month,
      companyId: String(this.props.filter.companyId),
      till: till.format('YYYY-MM-DD'),
    })
    runInAction(() => (this.downloading = false))
  }

  createVacationList = async () => {
    if (!this.props.filter.companyId) {
      alert('Bitte wählen Sie eine Firma für die Urlaubsliste aus.')
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('employeeVacationList', this.context.instance.id, {
      month: this.props.month,
      companyId: String(this.props.filter.companyId),
    })
    runInAction(() => (this.downloading = false))
  }

  private createSalaryList = async () => {
    if (!this.props.filter.companyId) {
      alert('Bitte wählen Sie vor dem Download eine Firma aus.')
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('employeeSalaryList', this.context.instance.id, {
      month: this.props.month,
      companyId: String(this.props.filter.companyId),
    })
    runInAction(() => (this.downloading = false))
  }

  private createReportFromDefinition = async (definitionId: string) => {
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'fromEmployeeReportDefinition',
      this.context.instance.id,
      {
        month: this.props.month,
        companyId: this.props.filter.companyId || null,
        scope: this.props.filter.scope,
        employeeReportDefinitionId: definitionId,
      },
    )
    runInAction(() => (this.downloading = false))
  }

  private createNewReportDefinition = async () => {
    this.props.navigate('/employees/reports/new')
  }

  downloadCurrentView = async () => {
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('workloadList', this.context.instance.id, {
      month: this.props.month,
      companyId: this.props.filter.companyId || null,
      scope: this.props.filter.scope,
    })
    runInAction(() => (this.downloading = false))
  }

  downloadStundenzettelUploadReport = async () => {
    if (!this.props.filter.companyId) {
      alert('Bitte wählen Sie eine Firma für den Report aus.')
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('stundenzettelUploadReport', this.context.instance.id, {
      month: this.props.month,
      companyId: String(this.props.filter.companyId),
    })
    runInAction(() => (this.downloading = false))
  }

  private createEmployeeCardsBatch = async () => {
    /*try {
      const userIds: string[] = []
      if (!this.props.employees.resources) {
        return
      }
      for (const employee of this.props.employees.resources) {
        if (this.props.employees.rerender.get(employee.id) === 1) {
          userIds.push(employee.id)
        }
      }
      if (!this.props.model.values.companyId) {
        toast.warning(
          'Wählen Sie eine Firma aus, für die die Ausweise erstellt werden sollen.',
        )
        return
      }
      if (userIds.length === 0) {
        toast.warning('Wählen Sie Mitarbeiter aus, um Ausweise für diese zu erstellen.')
        return
      }
      runInAction(() => (this.downloading = true))
      await hermes.create(`/api/${this.context.instance.id}/users/userCardsBatch`, {
        userIds,
        companyId: this.props.model.values.companyId,
      })
      await createAndDownloadReport(
        'employee-cards',
        this.context.instance.id,
        { userIds, companyId: this.props.model.values.companyId },
        'Ausweis.pdf',
      )
      runInAction(() => (this.downloading = false))
    } catch (_e) {
      toast.error('Die Ausweise konnten nicht erstellt werden.')
      runInAction(() => (this.downloading = false))
    }*/
  }

  private openKanzleiDialog = () => {
    this.props.navigate('/employees/kanzlei')
  }

  private editDefinition = (definitionId: string) => {
    this.props.navigate(`/employees/reports/${definitionId}`)
  }

  render() {
    return (
      <Menu as='div' className='relative'>
        <Menu.Button
          style={{
            borderRadius: this.context.permissions.workplan_sendPlans
              ? '0 6px 6px 0'
              : '6px',
            padding: '8px 0',
          }}
          className='w-[38px] text-gray-500 border-gray-300 border bg-white hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-500 hover:text-white focus:ring-gray-500 text-center shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 py-3 px-4'
        >
          {this.downloading ? (
            <span key={`${this.downloading}`}>
              <i className='fa fa-sync fa-spin' />
            </span>
          ) : (
            <span key={`${this.downloading}`}>
              <i className='fa fa-download' />
            </span>
          )}
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`top-10 max-h-[311px] overflow-auto right-0 origin-top-left absolute mt-2 py-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
            style={{ width: 224 }}
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.downloadCurrentView}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Aktuelle Ansicht
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.createStundenzettelByCompany}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Stundenzettel
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.downloadStundenzettelUploadReport}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Stundenzettel Uploadquote
                </button>
              )}
            </Menu.Item>
            {this.context.permissions.workplan_kanzleiexport && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={this.openKanzleiDialog}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    Kanzleiexport
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.createEmployeeCardsBatch}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Ausweise erstellen
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.createUserCardList}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Ausweisliste
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.createQualificationList}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Qualifikationsliste
                </button>
              )}
            </Menu.Item>
            {this.context.permissions.workplan_vacation && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={this.createVacationList}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    Urlaubsliste
                  </button>
                )}
              </Menu.Item>
            )}
            {this.context.permissions.workplan_vacation && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={this.downloadCurrentView}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    Urlaubsliste bis Datum
                  </button>
                )}
              </Menu.Item>
            )}
            {this.context.permissions.workplan_vacation && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={this.createSalaryList}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    Lohnbestandteile
                  </button>
                )}
              </Menu.Item>
            )}
            {this.context.permissions.workplan_customReports > 0 &&
              this.props.reports.resources?.map((option) => (
                <Menu.Item key={option.id}>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? 'bg-blue-500 text-white' : 'text-gray-900'
                      } group flex items-center w-full px-3 py-2 text-sm`}
                    >
                      <button
                        onClick={() => this.createReportFromDefinition(option.id)}
                        className='flex-auto truncate text-left'
                      >
                        {option.data?.label}
                      </button>
                      {this.context.permissions.workplan_customReports === 2 && (
                        <div className='flex-content'>
                          <RoundIcon
                            color='primary'
                            icon='fa fa-pen'
                            onClick={() => this.editDefinition(option.id)}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Menu.Item>
              ))}
            {this.context.permissions.workplan_customReports === 2 && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={this.createNewReportDefinition}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-blue-500'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    <span className='mr-1'>
                      <i className='fas fa-plus' />
                    </span>{' '}
                    Neuer Reporttyp
                  </button>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
