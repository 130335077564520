import { Resource } from '@byll/hermes'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import * as React from 'react'
import { box } from 'services/box'
import { InputBlock } from 'components/Form/components/InputBlock'
import { Model } from 'components/Form/Model'
import { AppContext } from 'services/connection/models/AppContext'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react'
import * as uuid from 'uuid'
import { DeleteEmployeeFileEntryPrompt } from './DeleteEmployeeFileEntryPrompt'
import { reaction } from 'mobx'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import { IEmployeeRecordBlock } from 'contracts/employees/interfaces/IEmployeeRecordBlock'
import { IInputBlockData } from 'contracts/inputBlock/interfaces/IInputBlockData'

interface Props {
  config: IInputBlockConfig
  data: IInputBlockData
  employee: IEmployeeSearchResult
  getOptions?: () => void
}

@observer
export class EmployeeRecordBlock extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id = uuid.v4()
  //private readonly model: Model<IEmployeeFileBlock>
  private disposer: Disposer[] = []

  constructor(props: Props) {
    super(props)
    //this.model = new Model(props.block.data)
  }

  private addEntry = () => console.log('addEntry')
  private openEditDialog = () => console.log('openEditDialog')
  private deleteEntry = () => console.log('deleteEntry')

  /*componentDidMount(): void {
    if (this.props.getOptions) {
      this.disposer.push(
        reaction(
          () => this.props.block.data?.details.length,
          () => this.props.getOptions!(),
          { fireImmediately: true },
        ),
      )
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposer)
  }

  private addEntry = () => {
    const id = uuid.v4()
    this.openEditDialog(id)
  }

  // id is only set for multiple blocks if one entry is edited or added
  private openEditDialog = (id?: string) => {
    if (!this.props.block.data) {
      return
    }
    const copiedBlock = cloneDeep(this.props.block.data)
    const promise = box.custom(
      <EmployeeFileEditDialog
        id={id}
        block={copiedBlock}
        employee={this.props.employee}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'lg' },
    )
  }

  private deleteEntry = (id: string) => {
    if (!this.props.block.data) {
      return
    }
    const promise = box.custom(
      <DeleteEmployeeFileEntryPrompt
        detailId={id}
        blockId={this.props.block.data.id}
        employeeId={this.props.employee.id}
        close={() => promise.close()}
      />,
      { context: this.context },
    )
  }*/

  render() {
    /*const { block } = this.props
    if (!block.data) {
      return null
    }
    if (block.data.id === 'employee-employment-contract') {
      return (
        <ContractPreview
          key={block.data.id}
          block={block}
          employee={this.props.employee}
          onDelete={this.deleteEntry}
        />
      )
    }*/
    return (
      <InputBlock
        id={this.id}
        config={this.props.config}
        data={this.props.data}
        disabled
        configurable
        onAdd={this.addEntry}
        onEdit={this.openEditDialog}
        onDelete={this.deleteEntry}
      />
    )
  }
}
