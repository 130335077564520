import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { InputDocument } from 'components/Form/components/InputDocument'
import { InputQualification } from 'components/Form/components/InputQualification'
import { InputText } from 'components/Form/components/InputText'
import { Message } from 'components/Message'
import { IEmployeeQualification } from 'contracts/employees/interfaces/IEmployeeQualification'
import { IQualification } from 'contracts/workload/interfaces/IQualification'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  qualification: IEmployeeQualification
  rQualification?: IQualification
  onClose: (id?: string) => void
}

@observer
export class EmployeeQualificationDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeQualification>
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ ...props.qualification })
  }

  private save = async () => {
    runInAction(() => (this.error = null))
    if (this.model.values.qualificationId === '0') {
      runInAction(() => {
        this.error = 'Bitte wählen Sie eine Qualifikation aus.'
      })
      return
    }

    if (
      this.model.values.trainingStartDate &&
      this.model.values.trainingEndDate &&
      this.model.values.trainingEndDate < this.model.values.trainingStartDate
    ) {
      runInAction(() => {
        this.error =
          'Das Enddatum der Ausbildung einer Qualifikation darf nicht vor dem Startdatum liegen.'
      })
      return
    }

    if (
      this.model.values.startDate &&
      this.model.values.endDate &&
      this.model.values.endDate < this.model.values.startDate
    ) {
      runInAction(() => {
        this.error =
          'Das Enddatum der Gültigkeit einer Qualifikation darf nicht vor dem Startdatum liegen.'
      })
      return
    }
    try {
      if (!this.model.values.id) {
        const { id } = await hermes.create(
          `/api/${this.context.instance.id}/employees/${this.props.qualification.userId}/qualifications`,
          { ...this.model.values, id: undefined, categoryId: undefined },
        )
        this.props.onClose(id)
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/employees/${this.props.qualification.userId}/qualifications/${this.model.values.id}`,
          { ...this.model.values, categoryId: undefined },
        )
        this.props.onClose(this.model.values.qualificationId)
      }
    } catch (_e) {
      runInAction(
        () =>
          (this.error = 'Beim Speichern der Qualifikation ist ein Fehler aufgetreten.'),
      )
    }
  }

  render() {
    console.log('render')
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='pb-4 text-left'>
          <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
            <span>{this.props.rQualification?.label || 'Neue Qualifikation'}</span>
            {this.props.rQualification?.short && (
              <span className='badge badge-primary ml-2'>
                {this.props.rQualification.short}
              </span>
            )}
          </Dialog.Title>
        </div>
        {this.error && (
          <Message className='mb-6' color='danger'>
            {this.error}
          </Message>
        )}
        {/* Line 1 */}
        <div className='flex mb-3 mt-3'>
          <InputQualification model={this.model} name='qualificationId' />
        </div>

        {/* Line 2 */}
        <div className='flex mb-3 gap-4'>
          <div className='flex-[0_0_115px]'>
            <InputDate
              model={this.model}
              name='trainingStartDate'
              label='Ausbildung von'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
          <div className='flex-[0_0_115px]'>
            <InputDate
              model={this.model}
              name='trainingEndDate'
              label='bis'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
          <div className='flex-auto'>
            <InputDocument
              label='Anhang'
              model={this.model}
              name='documentId'
              scope='employee'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
        </div>

        {/* Line 3 */}
        <div className='flex mb-3 gap-4'>
          <div className='flex-[0_0_115px]'>
            <InputDate
              model={this.model}
              name='startDate'
              label='Gültig von'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
          <div className='flex-[0_0_115px]'>
            <InputDate
              model={this.model}
              name='endDate'
              label='bis'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
          <div className='flex-auto'>
            <InputText
              label='Notizen'
              model={this.model}
              name='notes'
              disabled={this.context.permissions.user_hrQualifications !== 2}
            />
          </div>
        </div>
        <div
          className='flex gap-2 justify-end pt-4 sticky z-1 text-right bottom-0 bg-white'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Abbrechen
          </Button>
          <Button onClick={this.save}>Speichern</Button>
        </div>
      </div>
    )
  }
}
