import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { DialogOverlayArea } from 'components/Dialog/components/DialogOverlaySpinner/components/DialogOverlayArea'
import { CircularUploadProgress } from './CircularUploadProgress'
import { Message } from 'components/Message'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IDocumentTemplate } from 'contracts/general/interfaces/IDocumentTemplate'
import { hermes } from '@byll/hermes'
import { templates } from 'modules/Pdf/templates/ResidentDocumentTemplates/templates'
import { box } from 'services/box'
import { BatchCreateSuccess } from './BatchCreateSuccess'
import { storage } from 'services/storage'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { download } from 'helpers/download'
import { isLfgInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  onClose: () => void
  folder: IDocumentFolder
  document: IDocumentMetadata & { file?: Blob; hasUnsavedChanges?: boolean } // New document: document.id = ''
  resident: IResident
  templates: IDocumentTemplate[]
}

@observer
export class DocumentTemplateDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly templates: IDocumentTemplate[]
  @observable private selected: Map<string, IDocumentTemplate> = new Map() // templateId => Template (if selected). Unselect: templateId is deleteded from map.
  // This model is provided to the template component. If user fills
  // out anything in the template component, the data is stored here.
  // The data is then transferred to pdf generator as well and passed
  // into the component the same way.
  @observable.ref private templateModel = new Model({})
  @observable private creating = false
  @observable private error: string | null = null
  @observable private progress = { percentage: 1 }
  @observable private mode:
    | 'single'
    | 'single template'
    | 'multiple'
    | 'multiple download'
    | 'print' = 'single'
  private created: string[] | null = null
  private mounted = true
  private allTemplates = templates

  constructor(props: Props, context: AppContextProps) {
    super(props)
    if (storage.get(`document.mode.${context.user.id}`) === 'multiple') {
      this.mode = 'multiple'
    }

    // Only display template if it's allowed for the folder and current user
    this.templates = props.templates.filter((template) => {
      const permissionVariable = templates.get(template.component)?.permission
      if (permissionVariable && !context.permissions[permissionVariable]) {
        return false
      }
      const folderRestriction = templates
        .get(template.component)
        ?.folder?.find((p) => p.instanceId === context.instance.id)
      if (folderRestriction && folderRestriction.folderId !== props.folder.id) {
        return false
      }
      return true
    })
    makeObservable(this)
  }

  componentWillUnmount(): void {
    this.mounted = false
  }

  @action private setModeSingle = () => {
    this.mode = 'single'
    this.selected.clear()
    storage.set(`document.mode.${this.context.user.id}`, 'single')
  }
  @action private setModeMultiple = () => {
    this.mode = 'multiple'
    storage.set(`document.mode.${this.context.user.id}`, 'multiple')
  }

  @action
  private toggleTemplateSelection = (template: IDocumentTemplate) => {
    if (this.selected.has(template.id)) {
      this.selected.delete(template.id)
    } else if (this.mode === 'single') {
      this.selected.clear()
      this.selected.set(template.id, template)
      this.mode = 'single template'
      this.templateModel = new Model({})
    } else {
      this.selected.set(template.id, template)
    }
  }

  @action toggleAll = () => {
    if (this.selected.size > 0) {
      this.selected.clear()
      return
    }

    for (const template of this.templates) {
      this.selected.set(template.id, template)
    }
  }

  @action private backToTemplateSelection = () => {
    this.selected.clear()
    this.mode = 'single'
    this.templateModel = new Model({})
    this.error = null
  }

  private onSubmit = async (downloadAfterCreate?: boolean, forPrint?: boolean) => {
    if (this.creating) {
      return
    }
    if (this.selected.size === 0) {
      box.alert(
        'Keine Vorlage ausgewählt',
        'Bitte wählen Sie mindestens eine Vorlage aus, um ein Sammeldokument zu erzeugen.',
      )
      return
    }
    try {
      runInAction(() => {
        this.creating = true
        this.progress.percentage = 1
      })
      let hasConcatenated = true
      this.created = []
      let done = 0
      for (const [templateId, template] of this.selected) {
        if (template.type !== 'pdf') {
          hasConcatenated = false
        }
        const response = await hermes.create(
          `/api/${this.context.instance.id}/documents/fromTemplate`,
          {
            templateId,
            label: this.allTemplates.get(template.component)?.label ?? template.component,
            folderId: this.props.folder.id,
            residentId: this.props.resident.id,
            model: this.templateModel.values,
            size: this.allTemplates.get(template.component)?.size ?? null,
            qrCode: this.allTemplates.get(template.component)?.qrCode ?? false,
          },
          { timeout: 60000 },
        )
        if (!this.mounted) {
          return
        }
        done++
        action((percentage) => (this.progress.percentage = percentage))(
          done / this.selected.size - 0.01,
        )
        this.created.push(response.id)
      }
      this.props.document.hasUnsavedChanges = false
      if (this.mode === 'multiple') {
        if (hasConcatenated) {
          const response = await hermes.create(
            `/api/${this.context.instance.id}/documents/concat`,
            { documentIds: this.created },
          )
          if (forPrint) {
            await Promise.all(
              this.created.map((id) =>
                hermes.delete(`/api/${this.context.instance.id}/documents/files/${id}`),
              ),
            )
          }
          this.created.push(response.id)
          if (forPrint) {
            runInAction(() => {
              this.mode = 'print'
              this.creating = false
              this.error = null
            })

            setTimeout(() => {
              const btn = document.getElementById('PRINT_BUTTON')
              if (btn) {
                btn.click()
                console.log('clicked it')
              }
            }, 50)
          }
        } else {
          this.created = null
        }
        if (!forPrint) {
          runInAction(() => {
            this.mode = 'multiple download'
            this.creating = false
            this.error = null
          })
        }
      } else {
        if (downloadAfterCreate && !forPrint) {
          download(`/api/${this.context.instance.id}/documents/files/${this.created[0]}`)
          await hermes.delete(
            `/api/${this.context.instance.id}/documents/files/${this.created[0]}`,
          )
        }
        if (forPrint) {
          const response = await hermes.create(
            `/api/${this.context.instance.id}/documents/concat`,
            { documentIds: this.created },
          )
          this.created.push(response.id)
          await hermes.delete(
            `/api/${this.context.instance.id}/documents/files/${this.created[0]}`,
          )
          runInAction(() => {
            this.mode = 'print'
            this.creating = false
            this.error = null
          })
        } else {
          this.props.onClose()
        }
      }
    } catch (e: any) {
      runInAction(() => {
        if (e.id === ConflictError.id) {
          this.error = e.message
        } else {
          this.error = 'Fehler beim Erstellen der Vorlagen'
        }
        this.creating = false
      })
    }
  }

  render() {
    let view: JSX.Element
    let [single]: IDocumentTemplate[] = Array.from(this.selected.values())
    if (!this.creating && (this.mode === 'multiple download' || this.mode === 'print')) {
      view = (
        <BatchCreateSuccess
          text={
            this.mode === 'print'
              ? this.selected.size === 1
                ? 'Das Dokument wurde für den Druck vorbereitet.'
                : 'Die Dokumente wurden für den Druck vorbereitet'
              : `Ihre ausgewählten Dokumente wurden erfolgreich\nerstellt und im Ordner ${this.props.folder.label} gespeichert.`
          }
          button={this.mode === 'print' ? 'Jetzt ausdrucken' : 'Sammeldruck'}
          documentIds={this.created}
        />
      )
    } else if (!this.creating && this.mode === 'single template') {
      const Template = templates.get(single.component)?.component!
      view = (
        <div className='text-left overflow-x-scroll'>
          <Template
            resident={this.props.resident}
            user={this.context.user}
            instanceId={this.context.instance.id}
            model={this.templateModel}
          />
        </div>
      )
    } else if (!this.creating) {
      view = (
        <div className='grid grid-cols-4 gap-6'>
          {this.templates.map((template) => (
            <div
              key={template.id}
              onClick={() => this.toggleTemplateSelection(template)}
              className={`cursor-pointer rounded-md shadow-md overflow-hidden relative hover:border-2 hover:border-blue-500 hover:opacity-75${
                this.selected.has(template.id) ? ' border-2 border-blue-500' : ''
              }`}
            >
              <img
                src={templates.get(template.component)?.image}
                className='w-full'
                alt={templates.get(template.component)?.label}
              />
              <div className='absolute bottom-0 p-2 bg-gray-900 opacity-50 text-white h-[64px] w-full hyphens-manual'>
                {templates.get(template.component)?.label}
              </div>
              {this.selected.has(template.id) && (
                <div className='absolute -top-10 -left-10 p-2 bg-blue-500 text-white rotate-45 w-20 h-20'>
                  <span className='-rotate-45 absolute right-2 top-7'>
                    <i className='fas fa-check-circle' />
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    } else {
      view = <Spinner delay className='shadow bg-white relative flex-auto' />
    }

    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Bewohnerdokument aus Vorlage erstellen
            </Dialog.Title>
            <div className='truncate text-gray-400 text-sm mt-1'>
              <i className='far fa-folder' /> {this.props.folder.label}
              {this.mode === 'single template' && single && (
                <span>
                  &nbsp;&nbsp;
                  <i className='fas fa-caret-right' />
                  &nbsp;&nbsp;
                  {this.allTemplates.get(single.component)?.label ?? single.component}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6'>
          {this.error && (
            <Message
              color='danger'
              className={`${
                this.mode === 'multiple' ? 'mb-6' : 'mb-6'
              } border border-red-500 sticky top-0 z-10`}
            >
              {this.error}
            </Message>
          )}

          {(this.mode === 'single' || this.mode === 'multiple') && (
            <div className='-mt-3 mb-4'>
              <button
                onClick={this.setModeSingle}
                className={`focus:ring-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:opacity-80 px-2 py-1 text-xs font-bold leading-none border border-gray-500 ${
                  this.mode === 'single' ? 'text-white bg-gray-500' : 'text-gray-500'
                }`}
                style={{ borderRadius: '9999px 0 0 9999px' }}
              >
                Einzeldokument
              </button>
              <button
                onClick={this.setModeMultiple}
                className={`focus:ring-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:opacity-80 px-2 py-1 text-xs font-bold leading-none border border-gray-500 ${
                  this.mode === 'multiple' ? 'text-white bg-gray-500' : 'text-gray-500'
                }`}
                style={{ borderRadius: '0 9999px 9999px 0' }}
              >
                Sammeldokument
              </button>
              {this.mode === 'multiple' && (
                <>
                  <span className='text-gray-500 text-sm ml-3'>
                    Sie können alle ausgewählten Dokumente gemeinsam erstellen.
                  </span>
                  <button
                    onClick={this.toggleAll}
                    className={`float-right focus:ring-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 hover:opacity-80 px-2 py-1 text-xs font-bold leading-none border border-gray-500 text-gray-500`}
                    style={{ borderRadius: '9999px 9999px' }}
                  >
                    {this.selected.size === 0 ? 'Alle auswählen' : 'Alle abwählen'}
                  </button>
                </>
              )}
            </div>
          )}

          <div className='min-h-[280px] text-center flex flex-col'>{view}</div>
        </div>

        <div
          className='flex py-4 px-6 sticky bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          {this.mode === 'single template' && (
            <div className='flex-auto'>
              <Button
                disabled={this.creating}
                color='secondary'
                onClick={this.backToTemplateSelection}
              >
                Zurück
              </Button>
            </div>
          )}
          <div className='flex-content ml-auto'>
            <Button
              disabled={this.creating}
              color='secondary'
              outline={!!this.selected}
              onClick={this.props.onClose}
            >
              {this.mode !== 'multiple download' && this.mode !== 'print'
                ? 'Abbrechen'
                : 'Schließen'}
            </Button>
            {this.mode === 'single template' &&
              !isLfgInstance(this.context.instance.id) && (
                <Button
                  disabled={this.creating}
                  color={single.type === 'pdf' ? 'primary' : 'secondary'}
                  outline={single.type !== 'pdf'}
                  className='ml-2'
                  onClick={() => this.onSubmit(false)}
                >
                  Dokument speichern
                </Button>
              )}
            {this.mode === 'single template' &&
              !isLfgInstance(this.context.instance.id) && (
                <Button
                  disabled={this.creating}
                  color='primary'
                  className='ml-2'
                  onClick={() => this.onSubmit(true)}
                >
                  Dokument herunterladen
                </Button>
              )}
            {this.mode === 'single template' &&
              isLfgInstance(this.context.instance.id) && (
                <Button
                  disabled={this.creating}
                  color='primary'
                  className='ml-2'
                  onClick={() => this.onSubmit(true, true)}
                >
                  Dokument für den Druck vorbereiten
                </Button>
              )}
            {this.mode === 'multiple' && (
              <Button
                disabled={this.creating}
                color='primary'
                className='ml-2'
                onClick={() => {
                  if (isLfgInstance(this.context.instance.id)) {
                    this.onSubmit(false, true)
                  } else {
                    this.onSubmit()
                  }
                }}
              >
                {isLfgInstance(this.context.instance.id)
                  ? 'Dokumente für den Druck vorbereiten'
                  : 'Alle ausgewählten Dokumente erstellen'}
              </Button>
            )}
          </div>
        </div>

        {this.creating && !this.error && (
          <DialogOverlayArea opaque>
            <CircularUploadProgress
              onCancel={this.props.onClose}
              upload={this.progress}
            />
          </DialogOverlayArea>
        )}
      </div>
    )
  }
}
