import * as React from 'react'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { TransferState, UploadStripe } from '../InputImage/components/UploadStripe'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Menu, Transition } from '@headlessui/react'
import axios, { AxiosProgressEvent } from 'axios'
import { DocumentListItem } from './components/DocumentListItem'
import { download } from 'helpers/download'

interface Props {
  name: string
  model: Model<any>
  scope:
    | 'employee'
    | 'booking'
    | 'cost coverage'
    | 'floor plan'
    | 'migration file'
    | 'input block'
  label?: string
  className?: string
  style?: any
  disabled?: boolean
  id?: string
  accept?: string
  preview?: boolean
  hasError?: boolean
  onUpload?: (event: 'started' | 'finished' | 'failed') => void
}

const orientationClasses = {
  left: 'left-0',
  right: 'right-0',
}

@observer
export class InputDocumentMultiple extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id
  @observable private upload: TransferState | null = null
  @observable private documents: IDocumentMetadata[] | null = null
  @observable private orientation: 'left' | 'right' = 'left'
  private mounted = true
  private cancelUpload: (() => void) | null = null
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('upload-')
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        async (ids) => {
          if (!ids) {
            runInAction(() => {
              this.documents = null
              this.upload = null
            })
            return
          }
          this.loadDocuments()
        },
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
    dispose(this.disposers)
  }

  loadDocuments = async () => {
    try {
      const docs = await hermes.indexOnceNew<IDocumentMetadata>(
        `/api/${this.context.instance.id}/documents/metadata?scope=${
          this.props.scope
        }&documentIds=${this.props.model.values[this.props.name]}`,
      )
      runInAction(() => (this.documents = docs))
    } catch (_e) {
      runInAction(() => (this.documents = null))
    }
  }

  private triggerFileSelect = () => document.getElementById(this.id + '-input')?.click()

  private onSelectFile = async (e) => {
    const file = e.target.files[0]
    const name = file.name
    const form = new FormData()
    form.append('file', e.target.files[0])
    form.append('scope', this.props.scope)
    form.append('name', name)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    runInAction(() => {
      this.upload = { progress: 0.05, type: 'uploading' }
      // this.fileName = name
    })
    this.props.onUpload?.('started')
    const uploadPromise = axios.post(
      `/api/${this.context.instance.id}/documents/files`,
      form,
      {
        cancelToken: source.token,
        onUploadProgress: action((event: AxiosProgressEvent) => {
          if (event.total && event.loaded / event.total <= 1 && this.upload) {
            // Start at 5% to visualize pending upload for files that are scheduled to upload.
            this.upload.progress = 0.05 + 0.95 * (event.loaded / event.total)
          }
        }),
      },
    )
    try {
      const response: any = await uploadPromise
      if (!this.mounted) {
        return
      }
      runInAction(() => {
        if (this.upload) {
          this.upload.progress = 1
          this.upload.type = 'uploaded'
        }
        this.props.model.values[this.props.name] = this.props.model.values[
          this.props.name
        ]
          ? `${this.props.model.values[this.props.name]},${response.data.id}`
          : response.data.id
      })
      this.props.onUpload?.('finished')
    } catch (e) {
      runInAction(() => (this.upload = null))
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        alert('Beim Upload ist ein Fehler aufgetreten.')
      }
      this.props.onUpload?.('failed')
    }

    this.cancelUpload = null
    const input: HTMLInputElement | null = document.getElementById(
      this.id + '-input',
    ) as any
    if (input) {
      input.value = ''
    }
  }

  private deleteDocument = async (id: string) => {
    await hermes.delete(`/api/${this.context.instance.id}/documents/files/${id}`)
    runInAction(() => {
      this.props.model.values[this.props.name] = this.props.model.values[this.props.name]
        .split(',')
        .filter((i) => i !== id)
        .join(',')
    })
  }

  private onDownload = (id: string) => {
    download(`/api/${this.context.instance.id}/documents/files/${id}`)
  }

  @action
  onOpenDropdown = (e) => {
    const rect = e.currentTarget.getBoundingClientRect()
    const docWidth = document.documentElement.clientWidth
    if (rect.left + 320 > docWidth) {
      this.orientation = 'right'
    } else {
      this.orientation = 'left'
    }
  }

  render() {
    let innerClassName =
      'border border-gray-300 rounded-md block w-full h-[38px] relative focus:ring-indigo-500 focus:border-indigo-500 focus:border-2'
    const { name, model, label, className, style, disabled, accept, preview, hasError } =
      this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if ((touched && error) || hasError) {
      innerClassName =
        'border border-red-300 rounded-md block w-full h-[38px] relative focus:ring-red-500 focus:border-red-500 focus:border-2'
    }

    if (disabled) {
      innerClassName += ' bg-gray-100'
    }
    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400 z-1 truncate'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <Menu as='div' className='relative'>
          <Menu.Button
            className={innerClassName}
            style={style}
            onClick={this.onOpenDropdown}
          >
            <div className='flex justify-between items-center mx-2 text-gray-400 hover:text-indigo-400'>
              <span
                className={`px-1 ${
                  disabled ? 'bg-gray-100' : 'bg-white'
                } text-xs font-medium z-1 truncate`}
              >
                {this.documents?.length || 0}{' '}
                {this.documents?.length === 1 ? 'Dokument' : 'Dokumente'}
              </span>
              <span>
                <i className='fa fa-caret-down' />
              </span>
              {this.upload && <UploadStripe width='100%' state={this.upload} />}
            </div>
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={`origin-top-left absolute ${
                orientationClasses[this.orientation]
              } max-w-[320px] min-w-[240px] mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 overflow-auto`}
              style={{ maxHeight: '50vh' }}
            >
              {disabled && (!this.documents || this.documents.length === 0) && (
                <div className='px-4 py-2 text-sm text-gray-700 flex items-center'>
                  Keine Dokumente vorhanden.
                </div>
              )}
              {this.documents?.map((d) => (
                <Menu.Item key={d.id}>
                  {({ active }) => (
                    <div
                      className={active ? 'bg-gray-100' : ''}
                      onClick={() => this.onDownload(d.id)}
                    >
                      <DocumentListItem
                        document={d}
                        preview={preview}
                        onDelete={disabled ? undefined : this.deleteDocument}
                      />
                    </div>
                  )}
                </Menu.Item>
              ))}
              {!disabled && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        'px-4 py-2 text-sm text-gray-700 flex items-center cursor-pointer',
                        active ? 'bg-gray-100' : '',
                      )}
                      onClick={this.triggerFileSelect}
                    >
                      Dokument hinzufügen
                    </div>
                  )}
                </Menu.Item>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
        <input
          id={this.id + '-input'}
          type='file'
          className='hidden'
          multiple={false}
          onChange={this.onSelectFile}
          disabled={disabled}
          accept={accept}
        />
      </div>
    )
  }
}
