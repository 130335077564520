import * as React from 'react'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { Button } from '../Button'
import { action, makeObservable } from 'mobx'
export const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]

interface Props {
  model: Model<any>
  name: string
  onSelect?: (month: string) => void
  className?: string
  fromMonth?: string
  toMonth?: string
  disabled?: boolean
}

@observer
export class InputMonth extends React.Component<Props, {}> {
  private readonly rightButtonDisabledAt = dayjs().add(12, 'month').format('YYYY-MM')
  private readonly leftButtonDisabledAt = dayjs().subtract(12, 'month').format('YYYY-MM')

  constructor(props: Props) {
    super(props)
    if (props.fromMonth) {
      this.leftButtonDisabledAt = props.fromMonth
    }
    if (props.toMonth) {
      this.rightButtonDisabledAt = props.toMonth
    }
    makeObservable(this)
  }

  @action
  private leftButton = () => {
    const month = dayjs(this.props.model.values[this.props.name], 'YYYY-MM')
      .add(-1, 'month')
      .format('YYYY-MM')
    if (month <= this.leftButtonDisabledAt) {
      return
    }
    this.props.model.values[this.props.name] = month
    this.props.onSelect?.(month)
  }

  @action
  private rightButton = () => {
    const month = dayjs(this.props.model.values[this.props.name], 'YYYY-MM')
      .add(1, 'month')
      .format('YYYY-MM')
    if (month >= this.rightButtonDisabledAt) {
      return
    }
    this.props.model.values[this.props.name] = month
    this.props.onSelect?.(month)
  }

  render() {
    const split = this.props.model.values[this.props.name].split('-')
    const year: string = split[0]
    const month: number = +split[1]

    return (
      <div className={`flex ${this.props.className || ''}`}>
        <div className='flex-content'>
          <Button
            color={this.props.disabled ? 'secondary' : 'primary'}
            style={{ width: 38, borderRadius: '6px 0 0 6px' }}
            onClick={this.leftButton}
            disabled={
              !!this.props.disabled ||
              this.leftButtonDisabledAt >= this.props.model.values[this.props.name]
            }
          >
            <i className='fa fa-angle-left gray' />
          </Button>
        </div>
        <div
          className='px-3 flex-auto truncate text-center border-t border-b border-gray-300'
          style={{ lineHeight: '36px' }}
        >
          {`${months[month - 1]} ${year}`}
        </div>
        <div className='flex-content'>
          <Button
            color={this.props.disabled ? 'secondary' : 'primary'}
            style={{ width: 38, borderRadius: '0 6px 6px 0' }}
            onClick={this.rightButton}
            disabled={
              !!this.props.disabled ||
              this.rightButtonDisabledAt <= this.props.model.values[this.props.name]
            }
          >
            <i className='fa fa-angle-right gray' />
          </Button>
        </div>
      </div>
    )
  }
}
