import { Model } from 'components/Form/Model'
import { getInputBlockRowValidator } from 'contracts/inputBlock/helpers/getInputBlockDataValidator'
import { getValidInputBlockData } from 'contracts/inputBlock/helpers/getValidInputBlockData'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import { IInputBlockData } from 'contracts/inputBlock/interfaces/IInputBlockData'
import cloneDeep from 'lodash/cloneDeep'
import { toJS } from 'mobx'

export interface IInputBlockModel {
  data: { version: string; rows: Model<any>[] }
  config: IInputBlockConfig
}

export function getInputBlockModel(
  data: IInputBlockData,
  config: IInputBlockConfig,
): IInputBlockModel {
  const c = cloneDeep(toJS(config))
  const d = getValidInputBlockData(c, data)
  const validator = getInputBlockRowValidator(c)

  return {
    data: { version: d.version, rows: d.rows.map((row) => new Model(row, validator)) },
    config: c,
  }
}
