import * as React from 'react'
import { observer } from 'mobx-react'
import { RoundIcon } from 'components/RoundIcon'
import { AppContext } from 'services/connection/models/AppContext'
import { IEmployeeStundenzettel } from 'contracts/employees/interfaces/IEmployeeStundenzettel'
import { Model } from 'components/Form/Model'
import { MONTHS } from 'contracts/general/helpers/months'
import { dayjs } from 'helpers/dayjs'

interface Props {
  stundenzettel: IEmployeeStundenzettel
  onDelete: (id: string) => void
}

@observer
export class EmployeeStundenzettel extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeStundenzettel>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.stundenzettel)
  }

  private onPreview = (e) => {
    e.stopPropagation()
    window.open(`/documents/${this.props.stundenzettel.documentId}`, '_blank')
  }

  onDelete = () => this.props.onDelete(this.props.stundenzettel.id)

  render() {
    return (
      <div className='flex items-center mb-3 rounded-md p-2 border border-gray-300'>
        <div className='text-gray-500 flex-[0_0_120px]'>
          {MONTHS[+this.props.stundenzettel.month.split('-')[1] - 1]}{' '}
          {this.props.stundenzettel.month.split('-')[0]}
        </div>
        <div className='text-gray-800 ml-2 flex-auto truncate'>
          <span className='text-gray-500'>
            <i className='fa fa-user' />
          </span>
          <span className='ml-2'>
            von {this.props.stundenzettel.uploadedBy} am{' '}
            {dayjs(this.props.stundenzettel.createdAt).format('DD.MM.YYYY')}
          </span>
        </div>
        <div className='ml-auto flex-content flex gap-2'>
          <RoundIcon
            icon='fas fa-external-link-alt'
            color='primary'
            onClick={this.onPreview}
            tooltip={{ position: 'left', text: 'Vorschau' }}
          />
          {this.context.permissions.user_hrStundenzettelList === 2 && (
            <RoundIcon color='danger' icon='fa fa-trash' onClick={this.onDelete} />
          )}
        </div>
      </div>
    )
  }
}
