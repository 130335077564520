import * as React from 'react'

import styles from './styles.module.scss'

interface CalloutProps {
  icon: string
  title: string
  className?: string
  iconColor?: string
  iconClassName?: string
  subtitle?: string
  style?: any
  withoutCentering?: boolean
}

export class Callout extends React.Component<CalloutProps, {}> {
  render() {
    const iconStyle: any = {}

    if (this.props.iconColor) {
      iconStyle.color = this.props.iconColor
    }

    let className = this.props.className || ''

    if (!this.props.withoutCentering) {
      className += ' flex-grow flex flex-column'
    }

    return (
      <div className={className} style={this.props.style}>
        <div className='m-auto tc'>
          <div className='relative inline-block text-center max-w-xl'>
            <span className={`${styles.icon} ${this.props.iconClassName || ''}`}>
              <i className={this.props.icon} style={iconStyle} aria-hidden='true' />
            </span>
            <p
              className={`mt-4 mb-2 text-gray-500 font-bold whitespace-pre ${styles.title}`}
            >
              {this.props.title}
            </p>
            {this.props.subtitle && (
              <p className='text-gray-500'>{this.props.subtitle}</p>
            )}
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
