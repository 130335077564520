import { QRCode } from 'components/QrCode'
import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import * as React from 'react'
import logo from '../logo.png'

interface Props {
  signature: ICostCoverageSignature
  instanceId: string
  pageNumber: number
  totalNumberOfPages: number
  readOnly?: boolean // true: for pdf generation/print
}

export const CostCoverageSignatureHeader: React.FC<Props> = ({
  signature,
  instanceId,
  pageNumber,
  totalNumberOfPages,
  readOnly,
}) => (
  <div className='text-lg text-gray-500 leading-5 relative flex h-[84px]'>
    <div className='flex-[0_0_84px] mr-10 bg-white overflow-hidden'>
      {pageNumber === 1 && (
        <QRCode
          qrCodeId={signature.code || '0'}
          pageNumber={pageNumber}
          totalNumberOfPages={1}
          instanceId={instanceId}
          scale={3}
        />
      )}
    </div>
    <img src={logo} className='absolute right-0' alt='' style={{ width: 360 }} />
    {readOnly && (
      <div className='absolute right-0 -bottom-1 text-right text-sm text-black'>
        {`Seite ${pageNumber} / ${totalNumberOfPages}`}
      </div>
    )}
  </div>
)
