import * as React from 'react'
import { IInputBlockModel } from '../../helpers/getInputBlockModel'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import { makeObservable, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { ColumnSelect } from './components/ColumnSelect'
import { Model } from 'components/Form/Model'
import { RoundIcon } from 'components/RoundIcon'
import { Message } from 'components/Message'
import { SortableInputGrid } from './components/SortableInputGrid'

interface Props {
  id: string
  model: IInputBlockModel
}

@observer
export class InputBlockConfig extends React.Component<Props, {}> {
  @observable private error: string | null = null
  private readonly config: Model<IInputBlockConfig>

  constructor(props: Props) {
    super(props)
    this.config = new Model(toJS(props.model.config))
    makeObservable(this)
  }

  onCancel = () => {}
  onSubmit = () => {}

  render() {
    return (
      <div className='bg-white shadow-md rounded-md p-4 select-none'>
        <div className='flex'>
          <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
            {this.config.values.label}
          </div>
          <div className='flex-content flex gap-2'>
            {/*isCreatedByUser && (
              <RoundIcon
                icon='fa fa-trash'
                color='danger'
                onClick={this.deleteInputBlock}
              />
            )*/}
            <ColumnSelect
              model={this.config}
              name='cols'
              className='w-[68px]'
              label='Spalten'
              options={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
                { value: 7, label: '7' },
                { value: 8, label: '8' },
              ]}
            />
            <RoundIcon
              style={{ borderRadius: '9999px 0 0 9999px', marginRight: -8 }}
              icon='fa fa-times'
              color='danger'
              onClick={this.onCancel}
            />
            <RoundIcon
              classNameContainer='text-white bg-green-500'
              style={{ borderRadius: '0 9999px 9999px 0' }}
              icon='fa fa-check'
              color='success'
              onClick={this.onSubmit}
            />
          </div>
        </div>
        {this.error && (
          <Message className='mb-2' color='danger'>
            {this.error}
          </Message>
        )}
        <SortableInputGrid
          key={this.config.values.cols}
          id={this.props.id}
          config={this.config.values}
        />
      </div>
    )
  }
}
