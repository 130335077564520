import * as React from 'react'
import { NavLink, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { Spinner } from 'components/Spinner'
import { EmployeeDialogOverviewTab } from './components/EmployeeDialogOverviewTab'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { useResource } from 'helpers/useResource'
import { requestPendingError } from '@byll/hermes/lib/helpers/requestPendingError'
import { RoundIcon } from 'components/RoundIcon'
import { EmployeeDialogCardTab } from './components/EmployeeDialogCardTab'
import { EmployeeDialogRecordTab } from './components/EmployeeDialogRecordTab'

const tabs: Tab[] = [
  { label: 'Übersicht', to: `overview` },
  { label: 'Stundenkonto', to: `hours` },
  { label: 'Akte', to: `record`, permission: 'user_mitarbeiterakte' },
  { label: 'Ausweis', to: `card`, permission: 'user_hrCards' },
]

interface Props {
  month: string
  employeeId: string | null
}

interface Tab {
  label: string
  to: string
  permission?: string
}

export const EmployeeDialog: React.FC<Props> = observer(({ employeeId, month }) => {
  const navigate = useNavigate()
  const context = React.useContext(AppContext)
  const employee = useResource<IEmployeeSearchResult>(
    `/api/${context.instance.id}/workload/month/${month}/employeeSearchResults/${employeeId}`,
    { readOnly: true },
  )

  const onClose = () => navigate(`/employees`)

  return (
    <div className='min-w-[700px]'>
      {/* Close button */}
      <div className='sticky top-0 h-0 bg-red-500 relative' style={{ zIndex: 11 }}>
        <RoundIcon
          onClick={onClose}
          icon='fas fa-times'
          color='white'
          style={{
            position: 'absolute',
            top: 13,
            right: 24,
            borderRadius: '4px',
            width: 38,
            height: 38,
          }}
          tooltip={{ text: 'Dialog schließen', position: 'left' }}
        />
      </div>

      {/* Menu */}
      <div className='h-[64px] pt-0.5 border-b border-gray-300 px-6 sticky z-2 top-0 bg-gray-100'>
        <nav className='-mb-px flex space-x-8 flex-wrap' aria-label='Tabs'>
          {tabs.map((tab: Tab, i) => {
            if (
              tab.to !== 'record' &&
              tab.permission &&
              !context.permissions[tab.permission]
            ) {
              return null
            }
            return (
              <NavLink
                key={i}
                to={`${employeeId}/${tab.to}`}
                className={({ isActive }) =>
                  isActive
                    ? 'h-[62px] border-indigo-500 text-indigo-600 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                    : 'h-[62px] border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                }
                aria-current='page'
              >
                {tab.label}
              </NavLink>
            )
          })}
        </nav>
      </div>

      {employee.error && employee.error.id !== requestPendingError.id && (
        <div className='absolute top-16 bottom-0 left-0 right-0 flex flex-col'>
          <LoadingError title={'Beim Laden ist ein Fehler aufgetreten.'} />
        </div>
      )}
      {employee.error?.id === requestPendingError.id && <Spinner delay={400} />}
      {employee.data && (
        <EmployeeDialogTabs key={employee.id} employee={employee.data} month={month} />
      )}
    </div>
  )
})

export const EmployeeDialogTabs: React.FC<{
  employee: IEmployeeSearchResult
  month: string
}> = observer(({ employee, month }) => {
  const navigate = useNavigate()

  function onClose() {
    navigate(`/employees`)
  }

  return (
    <div>
      {/* Tab routes */}
      <Routes>
        <Route
          path={`${employee.id}/overview`}
          element={<EmployeeDialogOverviewTab month={month} employee={employee} />}
        />
        {/*<Route
          path={`${employee.id}/hours`}
          element={<EmployeeDialogHoursTab employee={employee} />}
        />*/}
        <Route
          path={`${employee.id}/record`}
          element={<EmployeeDialogRecordTab employee={employee} />}
        />
        <Route
          path={`${employee.id}/card`}
          element={<EmployeeDialogCardTab employee={employee} onClose={onClose} />}
        />
        <Route path='*' element={<Navigate to={`${employee.id}/overview`} replace />} />
      </Routes>
    </div>
  )
})
