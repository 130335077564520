import * as React from 'react'
import styles from './styles.module.scss'
import logo from './logo.png'
import { dayjs } from 'contracts/general/helpers/dayjs'
import axios from 'axios'
import { Wait } from 'modules/Pdf/components/Wait'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { getResidentSalutationDe } from 'contracts/residents/helpers/getResidentSalutationDe'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { QRCode } from 'components/QrCode'

export const LfgbErklaerungZurueckgelasseneHabe: React.FC<DocumentTemplateProps> = (
  props,
) => {
  const [residentSearchResult, setResidentSearchResult] =
    React.useState<IResidentSearchResult | null>(null)
  const [room, setRoom] = React.useState<IRoom | null>(null)
  const [building, setBuilding] = React.useState<IBuilding | null>(null)
  const [compound, setCompound] = React.useState<ICompound | null>(null)

  React.useEffect(() => {
    const load = async () => {
      const [r] = await Promise.all([
        axios.get<any>(
          `/api/${props.resident.instanceId}/residentSearchResults?residentIds=${props.resident.id}&fields=bookings`,
        ),
      ])
      if (!Array.isArray(r.data.resources) || r.data.resources.length !== 1) {
        throw new Error('Not found')
      }
      const stay = r.data.resources[0]?.data.bookings?.find(
        (b) => b.timeline === 'present' && b.type === 'internal-residence',
      )
      if (stay) {
        const rm = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/rooms/${stay.roomId}`,
        )
        const bl = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/buildings/${rm.data.buildingId}`,
        )
        const cp = await axios.get<any>(
          `/api/${props.resident.instanceId}/accommodations/compounds/${bl.data.compoundId}`,
        )
        setCompound(cp.data)
        setBuilding(bl.data)
        setRoom(rm.data)
      }
      setResidentSearchResult(r.data.resources[0])
    }
    load()
    // eslint-disable-next-line
  }, [])

  let buildingLabel = ''

  if (compound?.label === building?.label) {
    buildingLabel = compound?.label || ''
  } else {
    buildingLabel = compound && building ? compound?.label + ' / ' + building?.label : ''
  }

  if (!residentSearchResult) {
    return (
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        <Wait />
      </div>
    )
  }

  const stay = residentSearchResult.data.bookings?.find(
    (b) => b.timeline === 'present' && b.type === 'internal-residence',
  )
  return (
    <>
      <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
        {/* Header */}
        <div className='absolute top-[40px] left-[100px] right-[100px] flex'>
          <div className='flex-[0_0_100px]'>
            <QRCode
              qrCodeId={props.model.values.qrCode || '0'}
              pageNumber={1}
              totalNumberOfPages={1}
              instanceId={props.instanceId}
              scale={3}
            />
          </div>
        </div>

        <div className='text-lg font-bold mt-20'>Erklärung zu zurückgelassener Habe</div>

        <div className='mt-8 text-base flex'>
          <div className='flex-auto'>
            Unterkunft <span className='mx-2 underline'>{buildingLabel}</span>
          </div>
          <div className='flex-content'>
            Unterkunftskürzel{' '}
            <span className='mx-2 underline'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </div>

        <div className='grid grid-cols-2 border border-black text-base mt-2'>
          <div className='col-span-2 border-b border-black p-4 flex gap-10'>
            <div>{getResidentSalutationDe(props.resident.sex)}</div>
            <div>
              <div className='border-b border-black truncate min-w-[150px] max-w-[180px]'>
                {props.resident.lastName}&nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Name</div>
            </div>
            <div>
              <div className='border-b border-black truncate min-w-[150px] max-w-[180px]'>
                {props.resident.firstName}&nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Vorname</div>
            </div>
            <div>
              <div className='border-b border-black truncate'>
                {props.resident.dateOfBirth
                  ? dayjs(props.resident.dateOfBirth).format('DD.MM.YYYY')
                  : ''}
                &nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Geburtsdatum</div>
            </div>
          </div>
          <div className='border-r border-black p-4 flex gap-10'>
            <div>
              <div className='border-b border-black truncate min-w-[100px] max-w-[120px]'>
                {stay?.beginAt ? dayjs(stay.beginAt).format('DD.MM.YYYY') : ''}&nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Einzug am</div>
            </div>
            <div>
              <div className='border-b border-black truncate min-w-[100px] max-w-[120px]'>
                {room?.label || ''}&nbsp;
              </div>
              <div style={{ fontSize: '10px', lineHeight: '14px' }}>Zimmer-Nr.</div>
            </div>
          </div>
          <div></div>
        </div>

        {/* Content */}
        <div className='text-justify mt-8' style={{ fontSize: '16px' }}>
          <p>
            Als Bewohner*in der o.g. Wohnunterkunft bin ich darüber informiert worden,
            dass ich die von mir genutzte Wohnung bzw. den Wohnraum im Falle meines
            Auszugs geräumt zurückgeben muss.
          </p>

          <p className='mt-4'>
            Ich habe verstanden, dass die von mir dennoch zurückgelassene Habe bei
            offensichtlicher Wertlosigkeit nach maximal einem Monat entsorgt wird.
          </p>

          <p className='mt-4'>
            Dokumente und Wertgegenstände werden maximal 6 Monate aufbewahrt und im
            Anschluss daran ordnungsgemäß entsorgt. Dokumente mit personenbezogenen Daten
            werden datenschutzkonform entsorgt. Bargeld, Schmuck und besonders wertvolle
            Dinge gehen in das Eigentum des Landes Berlin über.
          </p>

          <p className='mt-4'>
            Eventuell zurückgelassene Ausweise, Zeugnisse und Urkunden werden an die
            ausstellende Behörde zurückgeschickt.
          </p>

          <div className='mt-12 mb-2 border-b border-black w-full' />

          <p className='mt-4'>
            Die*der Bewohner*in ist heute über die vorstehende Verfahrensweise informiert
            worden.
          </p>
        </div>

        <div className='grid gap-16 grid-cols-3 mt-12'>
          <div>
            <div className='border-b border-black'>{dayjs().format('DD.MM.YYYY')}</div>
            <div style={{ fontSize: '10px', lineHeight: '14px' }}>Datum</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div style={{ fontSize: '10px', lineHeight: '14px' }}>Bewohner*in</div>
          </div>

          <div>
            <div className='border-b border-black truncate'>&nbsp;</div>
            <div style={{ fontSize: '10px', lineHeight: '14px' }}>
              Unterkunftsverwaltung
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className='grid grid-cols-[160px_1fr_160px] border border-black absolute bottom-10 w-[640px]'>
          <div
            className='border-r border-black grid grid-cols-2 p-1 text-left'
            style={{ fontSize: '9px' }}
          >
            <div>Prozess-Nr.:</div>
            <div>PB-UK-01</div>
            <div>Version:</div>
            <div>01.02</div>
            <div>Formblatt:</div>
            <div>PB-UK-01-F01</div>
            <div>Gültig ab:</div>
            <div>05.12.2024</div>
          </div>
          <div className='font-bold text-base text-center pt-[6px]'>
            Formblatt zum Prozess „Umgang mit zurückgelassener Habe“
          </div>
          <div
            className='border-l border-black text-right p-1'
            style={{ fontSize: '9px' }}
          >
            Brunnenstraße 110 C, 13355 Berlin
            <br />
            Tel. +49 30 213 099 101
            <br />
            info@lfg-b.de
          </div>
        </div>
      </div>
    </>
  )
}
