import { RoundIcon } from 'components/RoundIcon'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  document: IDocumentMetadata
  preview?: boolean
  disabled?: boolean
  onDelete?: (id: string) => Promise<void>
}

export class DocumentListItem extends React.Component<Props, {}> {
  static contextType = AppContext

  private onPreview = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(`/documents/${this.props.document.id}`, '_blank')
  }

  render() {
    const { document, preview, disabled, onDelete } = this.props
    return (
      <div className='px-4 py-2 text-sm text-gray-700 flex items-center cursor-pointer'>
        <div className='truncate flex-auto'>{document.name}</div>
        <div className='flex-content ml-auto flex gap-2'>
          {preview && (
            <RoundIcon
              icon='fas fa-external-link-alt'
              color='primary'
              onClick={this.onPreview}
              tooltip={{ position: 'left', text: 'Vorschau' }}
            />
          )}
          {onDelete && !disabled && (
            <RoundIcon
              icon='fa fa-trash'
              color='danger'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete!(document.id)
              }}
              tooltip={{ position: 'left', text: 'Dokument löschen' }}
            />
          )}
        </div>
      </div>
    )
  }
}
