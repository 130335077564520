import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeSearchResultsMetadata } from 'contracts/employees/interfaces/IEmployeeSearchResultsMetadata'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { EmployeeListRow } from './components/EmployeeListRow'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { Collection, Resource } from '@byll/hermes'

interface Props {
  employeeId: string | null
  selected: Set<string>
  employees: Collection<
    IEmployeeSearchResult,
    IEmployeeSearchResultsMetadata,
    IEmployeeSearchResultsFilter
  >
}

@observer
export class EmployeeList extends React.Component<Props, {}> {
  private parentRef: HTMLDivElement | null = null
  private readonly disposers: Disposer[] = []
  @observable private readonly size = { width: 0, height: 0, small: false }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.debouncedResize)
    this.disposers.push(() => window.removeEventListener('resize', this.debouncedResize))
    this.disposers.push(
      reaction(
        () => this.props.employeeId,
        () => this.debouncedResize(),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onResize = action(() => {
    if (!this.parentRef) {
      return
    }
    const size = this.parentRef.getBoundingClientRect()
    this.size.width = size.width
    this.size.height = size.height
    this.size.small = size.width < 800
  })

  private debouncedResize: any = debounce(this.onResize, 200)

  private setParentRef = (ref: HTMLDivElement) => {
    if (!ref) {
      this.parentRef = null
    } else {
      this.parentRef = ref
      this.onResize()
    }
  }

  private renderRow = (resource: Resource<IEmployeeSearchResult>) => {
    const employee = resource.data
    if (!employee) {
      return null
    }

    return (
      <EmployeeListRow
        key={employee.id}
        employee={employee}
        selected={this.props.selected}
        size={this.size}
      />
    )
  }

  render() {
    const employees = this.props.employees

    if (employees.error?.id === RequestPendingError.id) {
      return <Spinner />
    }

    if (!employees.resources) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={1}>
          <Callout
            className='my-auto'
            icon='fas fa-exclamation-triangle'
            iconColor='#f56565'
            title='Fehler beim Laden der Mitarbeiter'
          />
        </div>
      )
    }

    if (employees.resources.length === 0) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={2}>
          <Callout
            className='my-auto'
            icon='fas fa-filter'
            iconColor='#DFE4E8'
            title={'Keine Mitarbeiter mit den aktuellen\nFilterkriterien gefunden'}
          />
        </div>
      )
    }

    return (
      <div
        ref={this.setParentRef}
        className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden'
      >
        {employees.resources.map(this.renderRow)}
        <div className='flex justify-center items-center'>
          <LoadMoreButton
            collection={this.props.employees}
            incrementBy={50}
            pluralText={`Sie sehen alle ${
              (this.props.employees.resources?.length || 0) < 5
                ? ''
                : this.props.employees.resources?.length
            } Mitarbeiter`}
          />
        </div>
      </div>
    )
  }
}
