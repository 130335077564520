import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observable, runInAction, reaction, toJS, action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { IUserCard } from 'contracts/userCard/interfaces/IUserCard'
import { ICompanyIdCardTemplate } from 'contracts/userCard/interfaces/ICompanyIdCardTemplate'
import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Spinner } from 'components/Spinner'
import { EmployeeIdCardFront } from 'modules/Companies/components/CompaniesContent/components/CompanyCardTab/components/EmployeeIdCardFront'
import { EmployeeIdCardBack } from 'modules/Companies/components/CompaniesContent/components/CompanyCardTab/components/EmployeeIdCardBack'
import { Model } from 'components/Form/Model'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { isPlainObject } from 'helpers/isPlainObject'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { Message } from 'components/Message'

const colors: InputSelectOption[] = [
  { value: '', label: 'Individuell' },
  { value: '#20ab2e', label: 'Grün' },
  { value: '#2b5e93', label: 'Dunkelblau' },
  { value: '#72c72f', label: 'Hellgrün' },
  { value: '#b96ad4', label: 'Lila' },
  { value: '#cad262', label: 'Gelbgrün' },
  { value: '#eeee00', label: 'Gelb' },
  { value: '#f74040', label: 'Rot' },
  { value: '#fcae2e', label: 'Orange' },
  { value: '#ff6600', label: 'Dunkelorange' },
]

interface Props {
  employee: IEmployeeSearchResult
  onClose: () => void
}

@observer
export class EmployeeDialogCardTab extends React.Component<Props, {}> {
  static contextType = AppContext

  @observable private view: 'loading' | 'create' | 'update' | 'error' = 'loading'
  @observable private changed = false
  @observable private error: string | null = null
  @observable private model: Model<IUserCard> | null = null
  @observable private companies: InputSelectOption[] = []
  private companyIdCardTemplates: { [companyId: string]: Model<ICompanyIdCardTemplate> } =
    {}
  private resetting = false
  private dispose: Disposer | null = null
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.reset()
  }

  componentWillUnmount(): void {
    if (this.dispose) {
      this.dispose()
      this.dispose = null
    }
    dispose(this.disposers)
  }

  reset = async () => {
    if (this.resetting) {
      return
    }
    this.resetting = true
    try {
      runInAction(() => (this.view = 'loading'))
      // Load required data
      const data: {
        userCard: IUserCard
        companyIdCardTemplates: { [companyId: string]: ICompanyIdCardTemplate }
        companies: { id: string; name: string }[]
      } = await hermes.getOnceNew(
        `/api/${this.context.instance.id}/users/userCards/${this.props.employee.id}`,
      )

      if (!isPlainObject(data) || !data.hasOwnProperty('userCard')) {
        throw new Error('Der Ausweis konnte nicht geladen werden')
      }
      const templates: { [companyId: string]: Model<ICompanyIdCardTemplate> } = {}
      for (const key of Object.keys(data.companyIdCardTemplates)) {
        templates[key] = new Model(data.companyIdCardTemplates[key])
      }
      this.companyIdCardTemplates = templates

      runInAction(() => {
        this.companies = [
          { value: '', label: 'Firma wählen...' },
          ...data.companies.map((company) => ({
            value: company.id,
            label: company.name,
          })),
        ]
        this.model = new Model(data.userCard)
        this.changed = false
        this.view = isPlainObject(data.userCard) ? 'update' : 'create'
      })

      if (this.dispose) {
        this.dispose()
        this.dispose = null
      }
      this.dispose = reaction(
        () => toJS(this.model?.values),
        () => {
          if (this.dispose) {
            this.dispose()
            this.dispose = null
          }
          this.changed = true
        },
      )
    } catch (e: any) {
      runInAction(() => {
        this.error =
          (typeof e === 'string' ? e : e.message) ||
          'Der Ausweis konnte nicht geladen werden'
        this.view = 'error'
      })
    }
    this.resetting = false
  }

  onSave = async () => {
    if (this.view === 'loading' || !this.model) {
      return
    }

    // Validate
    const card: any = toJS(this.model.values)
    if (card.color.trim() === '') {
      alert('Bitte geben Sie eine Farbe an.')
      return
    }
    if (card.firstName.trim() === '') {
      alert('Bitte geben Sie einen Vornamen an.')
      return
    }
    if (card.lastName.trim() === '') {
      alert('Bitte geben Sie einen Nachnamen an.')
      return
    }

    try {
      runInAction(() => {
        this.view = 'loading'
      }) // Triggers loading
      const data = {
        companyId: card.companyId === 'null' ? null : card.companyId,
        color: card.color,
        firstName: card.firstName,
        lastName: card.lastName,
        bewacherId: card.bewacherId,
      }
      await hermes.patch(
        `/api/${this.context.instance.id}/users/userCards/${card.id}`,
        data,
      )
      await createAndDownloadReport(
        'employee-cards',
        this.context.instance.id,
        { userIds: [card.userId], companyId: card.companyId },
        'Ausweis.pdf',
      )
      // await backend.create('Employee\\Card', { userId: card.userId })
      runInAction(() => {
        this.error = null
        this.changed = false // Prevents route change message on close
        this.view = 'update'
      })
    } catch (_e) {
      runInAction(() => {
        this.error = 'Der Ausweis konnte nicht gespeichert werden'
        this.changed = false // Prevents route change message on close
        this.view = 'error'
      })
    }
  }

  onCreate = async () => {
    try {
      runInAction(() => {
        this.view = 'loading'
      }) // Triggers loading
      await hermes.create(`/api/${this.context.instance.id}/users/userCards`, {
        userId: this.props.employee.id,
      })
      await this.reset()
      runInAction(() => {
        this.error = null
        this.changed = false // Prevents route change message on close
        this.view = 'update'
      })
    } catch (_e) {
      runInAction(() => {
        this.error = 'Der Ausweis konnte nicht erstellt werden'
        this.changed = false // Prevents route change message on close
        this.view = 'error'
      })
    }
  }

  @action setCompany = (companyId: number | null) => {
    if (!this.model) {
      return
    }
    this.model.values.companyId = String(companyId)
  }

  render() {
    const permissions = this.context.permissions

    if (this.error) {
      return (
        <div className='p-6'>
          <Message color='danger' className='border border-red-500 mb-4'>
            {this.error}
          </Message>
          <Button onClick={this.props.onClose} color='primary'>
            Schließen
          </Button>
        </div>
      )
    }

    if (this.view === 'create') {
      return (
        <div className='p-6'>
          <Message color='primary' className='border border-indigo-500 mb-4'>
            Dieser Mitarbeiter hat noch keinen Ausweis
          </Message>
          <Button
            color='primary'
            onClick={this.onCreate}
            disabled={permissions.user_hrCards !== 2}
          >
            Ausweis anlegen
          </Button>
        </div>
      )
    }

    if (this.view === 'loading' || !this.model) {
      return <Spinner />
    }

    const template = this.companyIdCardTemplates[this.model.values.companyId || '']
    return (
      <div className='flex ml-4 mr-6 my-2.5'>
        {/* Card preview */}
        <div className='flex-[0_0_353px] h-[447px] overflow-hidden pl-2 pr-4'>
          <div className='scale-75 origin-top-left' key={this.model.values.companyId}>
            <EmployeeIdCardFront
              userCard={this.model.values}
              companyIdCardTemplate={template}
            />
            <EmployeeIdCardBack
              userCard={this.model.values}
              companyIdCardTemplate={template}
            />
          </div>
        </div>

        {/* Card settings */}
        <div className='flex-auto flex flex-col gap-5 pt-3.5'>
          <InputSelect
            model={this.model}
            name='companyId'
            label='Firma'
            disabled={permissions.user_hrCards !== 2}
            options={this.companies}
          />
          <div className='flex'>
            <div style={{ flex: '0 0 140px', marginRight: 15 }}>
              <InputSelect
                model={this.model}
                name='color'
                label='Farbe'
                options={colors}
                disabled={permissions.user_hrCards !== 2}
              />
            </div>
            <div className='flex-auto'>
              <InputText
                model={this.model}
                name='color'
                disabled={permissions.user_hrCards !== 2}
              />
            </div>
          </div>

          <InputText
            model={this.model}
            name='firstName'
            label='Vorname'
            disabled={permissions.user_hrCards !== 2}
          />
          <InputText
            model={this.model}
            name='lastName'
            label='Nachname'
            disabled={permissions.user_hrCards !== 2}
          />

          <InputText
            model={this.model}
            name='bewacherId'
            label='Bewacher ID'
            disabled={permissions.user_hrCards !== 2}
          />

          <div className='flex'>
            <Button
              className='flex-auto'
              color='secondary'
              outline
              onClick={this.props.onClose}
              style={{ borderRadius: '6px 0 0 6px' }}
            >
              Schließen
            </Button>
            {permissions.user_hrCards === 2 && (
              <Button
                className='flex-auto'
                color='primary'
                onClick={this.onSave}
                style={{ borderRadius: '0 6px 6px 0' }}
              >
                <i className='fa fa-download' /> Download
              </Button>
            )}
          </div>
        </div>

        {/* Display warning if route is about to be changed unsaved */}
        {this.changed && <PreventRouteChange />}
      </div>
    )
  }
}
