import { Model } from 'components/Form/Model'
import * as React from 'react'
import { observer } from 'mobx-react'
import { RoundIcon } from 'components/RoundIcon'
import { action, makeObservable, observable, reaction } from 'mobx'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlockField'
import { InputSelectOption } from './ColumnSelect'
import { colSpanClasses, selectOptions } from '../../InputBlockData'
import { IfOutsideClick } from 'components/IfOutsideClick'
import { InputText } from 'components/Form/components/InputText'
import { InputSelect } from 'components/Form/components/InputSelect'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'

interface Props {
  id: string
  field: IInputBlockField
  config: IInputBlockConfig
  onDelete: (key: string) => void
}

const orientationClasses = {
  left: 'left-0',
  right: 'right-0',
}

const editingFieldKey = observable.box<string | null>(null)

@observer
export class EditField extends React.Component<Props, {}> {
  private model: Model<IInputBlockField>
  @observable private optionModel: string | null
  @observable private orientation: 'left' | 'right' = 'left'
  private disposers: Disposer[] = []
  private readonly editingKey: string

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.editingKey = `${props.id}.${props.field.key}`
    this.model = new Model(props.field)
    this.optionModel =
      props.field.type === 'select' ? props.field.options.map((o) => o).join(',') : ''
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.props.field.type,
        () => this.setOptionModel(),
      ),
      reaction(
        () => this.props.config.cols,
        () => this.checkColSpan(),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private checkColSpan = () => {
    if (this.props.field.colspan > this.props.config.cols) {
      this.model.values.colspan = this.props.config.cols
    }
  }

  @action private setOptionModel = () => {
    if (this.props.field.type === 'select') {
      this.optionModel = this.props.field.options
        ? this.props.field.options.map((o) => o).join(',')
        : ''
    }
  }

  @action private handleOptionChange = (e) => {
    if (this.model.values.type !== 'select') {
      return
    }
    this.optionModel = e.target.value
    this.model.values.options = e.target.value.split(',').map((o) => o.trim())
  }

  private getColspanOptions = () => {
    const options: InputSelectOption[] = []
    for (let i = 1; i <= this.props.config.cols; i++) {
      options.push({ value: i, label: i.toString() })
    }
    return options
  }

  private checkAllowEmpty = action((event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      this.model.values.input = 'required'
    } else {
      this.model.values.input = 'optional'
    }
  })

  @action private editField = (_field: IInputBlockField, e) => {
    e.stopPropagation()
    const rect = e.currentTarget.getBoundingClientRect()
    const docWidth = document.documentElement.clientWidth
    if (rect.left + 420 > docWidth) {
      this.orientation = 'right'
    } else {
      this.orientation = 'left'
    }
    editingFieldKey.set(
      editingFieldKey.get() === this.editingKey ? null : this.editingKey,
    )
  }

  @action private onOutsideClick = () => {
    editingFieldKey.set(null)
  }

  render() {
    const isRequired = this.props.field.config === 'immutable'
    const colSpan =
      this.props.field.colspan > this.props.config.cols
        ? this.props.config.cols
        : this.props.field.colspan
    return (
      <div className={`relative ${colSpanClasses[colSpan]}`}>
        <div
          className={`border border-gray-300 rounded-md block w-full h-[38px] relative bg-white cursor-move`}
          onClick={(e) => this.editField(this.props.field, e)}
        >
          <span
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400 truncate max-w-full'
            style={{ left: 8, top: -8 }}
          >
            {this.props.field.label}
          </span>
        </div>
        {this.editingKey === editingFieldKey.get() && (
          <IfOutsideClick
            className={`absolute top-12 ${
              orientationClasses[this.orientation]
            } z-10 w-[382px] p-4 shadow-lg border border-gray-300 bg-white rounded-md`}
            onOutsideClick={this.onOutsideClick}
          >
            <div className='flex items-center gap-4 '>
              <InputText
                className='flex-auto'
                model={this.model}
                name='label'
                label='Feldbezeichnung'
                disabled={isRequired}
              />
              <InputSelect
                model={this.model}
                className='flex-content'
                name='colspan'
                label='Spalten'
                options={this.getColspanOptions()}
              />

              {!isRequired && (
                <RoundIcon
                  classNameContainer='flex-content'
                  icon='fa fa-trash'
                  color='danger'
                  onClick={() => this.props.onDelete(this.props.field.key)}
                  tooltip={{ position: 'left', text: 'Feld löschen' }}
                />
              )}
            </div>
            <div className='flex items-center gap-4 mt-4'>
              <InputSelect
                model={this.model}
                name='type'
                label='Typ'
                options={selectOptions}
                disabled={isRequired}
              />
              <div className={`relative flex items-center`}>
                <input
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  maxLength={255}
                  type='checkbox'
                  name='allowEmpty'
                  onChange={this.checkAllowEmpty}
                  checked={this.model.values.input === 'required'}
                  id={'checkbox-allow-empty'}
                />
                <label
                  htmlFor={'checkbox-allow-empty'}
                  className='ml-2 block text-sm text-gray-900'
                >
                  Pflichtfeld
                </label>
              </div>
            </div>
            {this.props.field.type === 'select' && (
              <div className='mt-2'>
                <div className='text-sm text-indigo-400 mb-2'>
                  Geben Sie die Auswahloptionen mit Komma getrennt (A,B,C,...) ein.
                </div>
                <input
                  className='w-full border border-gray-300 rounded-md p-2'
                  value={this.optionModel || ''}
                  onChange={this.handleOptionChange}
                  disabled={isRequired}
                />
              </div>
            )}
            {this.props.field.type === 'dropdown' && (
              <div className='mt-2'>
                <InputText
                  model={this.model}
                  name='dropdownEntryType'
                  label='Dropdown Type'
                  placeholder='Automatisch'
                  disabled={!this.props.field.key.includes('unique_')}
                />
              </div>
            )}
          </IfOutsideClick>
        )}
      </div>
    )
  }
}
