import * as React from 'react'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlockField'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputDocumentMultiple } from 'components/Form/components/InputDocumentMultiple'
import { InputDocument } from 'components/Form/components/InputDocument'
import { InputDropdownEntry } from 'components/Form/components/InputDropdownEntry'
import { InputCompany } from 'components/Form/components/InputCompany'
import { colSpanClasses } from '..'

interface Props {
  field: IInputBlockField
  model: Model<any>
  disabled?: boolean
}

@observer
export class InputBlockField extends React.Component<Props, {}> {
  private readonly selectOptions: InputSelectOption[] = []
  constructor(props: Props) {
    super(props)
    if (props.field.type === 'select') {
      this.selectOptions = props.field.options.map((label) => ({
        value: label,
        label: label,
      }))
      this.selectOptions.unshift({ value: '', label: '' })
    }
  }

  render() {
    const { field, model, disabled } = this.props
    switch (field.type) {
      case 'text':
        return (
          <InputText
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
          />
        )
      case 'date':
        return (
          <InputDate
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
          />
        )
      case 'select':
        return (
          <InputSelect
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            options={this.selectOptions}
            disabled={disabled}
          />
        )
      case 'file':
        return (
          <InputDocument
            scope='input block'
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
            preview
          />
        )
      case 'files':
        return (
          <InputDocumentMultiple
            scope='input block'
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
            preview
          />
        )
      case 'dropdown':
        return (
          <InputDropdownEntry
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
            type={field.dropdownEntryType}
            allowCreate
          />
        )
      case 'company':
        return (
          <InputCompany
            className={colSpanClasses[field.colspan]}
            model={model}
            name={field.key}
            label={field.label}
            disabled={disabled}
          />
        )
      default:
        return null
    }
  }
}
