import * as React from 'react'

interface IfOutsideClickProps {
  onOutsideClick: (event: MouseEvent) => void
  [key: string]: any
}

export class IfOutsideClick extends React.Component<IfOutsideClickProps, {}> {
  ref: HTMLDivElement | null = null

  componentDidMount() {
    document.addEventListener('click', this.onOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick)
  }

  onOutsideClick = (event: MouseEvent) => {
    // Sometimes this component gets unmounted before click arrives
    // because this doesn't use reacts event system. Therefore check ref first.
    if (this.ref && !this.ref.contains(event.target as HTMLElement)) {
      this.props.onOutsideClick(event)
    }
  }

  setRef = (ref: HTMLDivElement) => {
    this.ref = ref
  }

  render() {
    const { onOutsideClick: doFunc, children, ...rest } = this.props

    return (
      <div ref={this.setRef} {...rest}>
        {this.props.children}
      </div>
    )
  }
}
