import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isUuid } from '../../general/helpers/isUuid'
import { isYmdDate } from '../../general/helpers/isYmdDate'
import { IInputBlockConfig } from '../interfaces/IInputBlockConfig'
import { IInputBlockData } from '../interfaces/IInputBlockData'
import * as uuid from 'uuid'

const EMPTY_UUID = '1d8957b6-99ac-41aa-bdea-ac38095be1bf'

export function getValidFirstRowData<D>(
  config: IInputBlockConfig,
  data?: IInputBlockData,
): D & { id: string } {
  return fixRow(config, data?.rows?.[0] || { id: EMPTY_UUID }) as D & { id: string }
}

export function getValidInputBlockData(
  config: IInputBlockConfig,
  data?: IInputBlockData,
): IInputBlockData {
  if (!data) {
    if (config.multiple) {
      return { version: EMPTY_UUID, rows: [] }
    } else {
      return { version: EMPTY_UUID, rows: [fixRow(config, { id: EMPTY_UUID })] }
    }
  }

  if (config.multiple) {
    return {
      version: data.version,
      rows: data.rows.map((row) => fixRow(config, row)),
    }
  } else {
    return {
      version: data.version,
      rows: [fixRow(config, data.rows[0] || { id: EMPTY_UUID })],
    }
  }
}

function fixRow(
  config: IInputBlockConfig,
  data: Record<string, any>,
): IInputBlockData['rows'][0] {
  const model: any = {}
  for (const field of config.fields) {
    if (field.key === 'id') {
      throw new Error('Field key "id" is reserved')
    }
    let value = data[field.key]
    switch (field.type) {
      case 'text':
        if (typeof value !== 'string') {
          value = ''
        }
        break
      case 'date':
        if (!isYmdDate(value)) {
          value = null
        }
        break
      case 'select':
        if (!field.options.find((o: string) => o === value)) {
          value = '' // Empty item is always rendered in dropdown
        }
        break
      case 'file':
        if (!isUuid(value)) {
          value = null
        }
        break
      case 'files':
        const uuids = typeof value === 'string' ? value.split(',') : []
        value = uuids.filter(isUuid).join(',')
        break
      case 'company':
        if (!isIntegerString(value)) {
          value = null
        }
        break
      case 'dropdown':
        if (typeof value !== 'string') {
          value = ''
        }
        break
      default:
        throw new Error(`Unknown field type: ${(field as any).type}`)
    }
    model[field.key] = value
  }
  model.id = isUuid(data.id) ? data.id : uuid.v4()
  return model
}
