import { Tooltip } from 'components/Tooltip'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { dayjs } from 'helpers/dayjs'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  size: { small: boolean }
  employee: IEmployeeSearchResult
  selected: Set<string>
  style?: any
}

const onCheck = action((employee: IEmployeeSearchResult, selected: Set<string>) => {
  if (selected.has(employee.id)) {
    selected.delete(employee.id)
  } else {
    selected.add(employee.id)
  }
})

export const EmployeeListRow: React.FC<Props> = observer(
  ({ employee, style, size, selected }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const context = React.useContext(AppContext)
    const active = location.pathname.indexOf(`/${employee.id}/`) !== -1

    function onOpen() {
      if (active) {
        navigate(`/employees`)
        return
      }

      const path = window.location.pathname.replace(/\/$/, '').toLowerCase()
      if (path.endsWith('/employees')) {
        navigate(`/employees/${employee.id}/overview`)
      } else {
        // Navigate to different user but same open tab as before
        navigate(`/employees/${employee.id}/${path.split('/').pop()}`)
      }
    }

    return (
      <div
        style={style}
        className={`border-b border-gray-200 h-[64px] flex py-2 ${
          active ? 'bg-indigo-500' : ''
        }`}
      >
        <div className='ml-6 flex-[0_0_30px] pt-[11px]'>
          <input
            className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
            type='checkbox'
            checked={selected.has(employee.id)}
            onChange={() => onCheck(employee, selected)}
          />
        </div>
        <img
          className='mr-2 h-12 w-12 flex-content rounded-full bg-gray-50'
          src={getUserImageSrc(
            context.instance.id,
            employee.imageId,
            employee.sex,
            'thumbnail',
          )}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='mr-6 min-w-0 flex-auto truncate'>
          <div className='mt-1 flex items-center gap-x-1.5'>
            <div
              onClick={onOpen}
              className={`flex-[0_0_auto] lg:flex-[0_1_auto] cursor-pointer hover:underline truncate ${
                active ? 'text-white' : 'text-black hover:text-blue-500'
              }`}
            >
              {`${employee.title} ${employee.lastName.toUpperCase()}, ${
                employee.firstName
              }`}
            </div>
            <div className='flex-auto'>
              <span
                className={`has-tooltip flex-content inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium text-white ${
                  employee.utilization > 90 && employee.utilization < 110
                    ? 'bg-green-500'
                    : 'bg-yellow-500'
                }`}
              >
                {employee.utilization > 90 && employee.utilization < 110 ? (
                  <span className='mr-1'>
                    <i className='fas fa-check-circle' />
                  </span>
                ) : employee.utilization > 110 ? (
                  <span className='mr-1'>
                    <i className='fas fa-arrow-circle-down' />
                  </span>
                ) : (
                  <span className='mr-1'>
                    <i className='fas fa-arrow-circle-up' />
                  </span>
                )}
                {`${employee.utilization} %`}
                <Tooltip position='right'>Auslastung</Tooltip>
              </span>
            </div>

            <div className={size.small ? 'flex-[0_0_auto]' : 'flex-[0_0_20%]'}>
              {employee.planSendDate ? (
                <span className='inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-paper-plane' />
                  </span>
                  <span
                    className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}
                  >{`Arbeitsplan versendet ${dayjs(employee.planSendDate).format(
                    'DD.MM.',
                  )}`}</span>
                </span>
              ) : (
                <span className='inline-flex items-center rounded-full bg-gray-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-paper-plane' />
                  </span>
                  <span
                    className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}
                  >{`Arbeitsplan nicht versendet`}</span>
                </span>
              )}
            </div>

            <div
              className={size.small ? 'flex-[0_0_auto]' : 'flex-[0_0_80px] text-right'}
            >
              {employee.plannable === 'yes' && (
                <span className='inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-unlock' />
                  </span>
                  <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                    Planbar
                  </span>
                </span>
              )}
              {employee.plannable === 'no' && (
                <span className='inline-flex items-center rounded-full bg-yellow-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-lock' />
                  </span>
                  <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                    Gesperrt
                  </span>
                </span>
              )}
              {employee.plannable === 'partial' && (
                <span className='inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-unlock' />
                  </span>
                  <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                    Tageweise
                  </span>
                </span>
              )}
            </div>
          </div>
          <div
            className={`truncate text-xs leading-5  ${
              active ? 'text-white' : 'text-gray-500'
            }`}
          >
            {employee.contracts.length === 0 && <span>Kein Vertrag</span>}
            {employee.contracts.map((c, i) => (
              <span key={c.id} className='mr-1'>
                {i !== 0 && <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>}
                <span
                  className={`has-tooltip rounded-full ${
                    c.salarySlip ? 'bg-green-500' : 'bg-red-500'
                  } text-white px-1 mr-1 w-4 h-4 pt-[2px] relative top-[1px] inline-flex`}
                >
                  <i className='fas fa-euro-sign' />
                  <Tooltip position='right'>
                    {c.salarySlip ? 'Gehaltszettel versendet' : 'Gehaltszettel fehlt'}
                  </Tooltip>
                </span>
                <span className='max-w-[100px] truncate inline-flex'>
                  {c.company.name}
                </span>
              </span>
            ))}
          </div>
        </div>
      </div>
    )
  },
)
