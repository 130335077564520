import { ICostCoverageSignature } from 'contracts/costCoverages/interfaces/ICostCoverageSignature'
import * as React from 'react'
import styles from './styles.module.scss'
import { DocumentTemplateProps } from '../../interfaces/DocumentTemplateProps'
import { Wait } from 'modules/Pdf/components/Wait'
import axios from 'axios'
import { CostCoverageSignature } from '../../../CostCoverageSignature'
import { runInAction } from 'mobx'
import { dayjs } from 'helpers/dayjs'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputMonth } from 'components/Form/components/InputMonth'
import { observer } from 'mobx-react-lite'
import { Spinner } from 'components/Spinner'

export const LfgbCostCoverageSignature: React.FC<DocumentTemplateProps> = observer(
  (props) => {
    const [signatures, setSignatures] = React.useState<ICostCoverageSignature[] | null>(
      null,
    )
    const { month, compoundId } = props.model.values

    React.useEffect(() => {
      runInAction(() => {
        props.model.values.compoundId = props.model.values.compoundId || null
        props.model.values.month =
          props.model.values.month ||
          dayjs().startOf('month').add(-1, 'month').format('YYYY-MM')
      })
      // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
      const load = async (month?: string, compoundId?: string | null) => {
        if (!month) {
          return
        }
        setSignatures(null)
        const data = await axios.get(
          `/api/${props.instanceId}/residents/${
            props.resident.id
          }/costCoverageSignatures?generic=yes&month=${month}${
            compoundId ? `&compoundId=${compoundId}` : ''
          }`,
        )
        for (const s of data.data.resources) {
          s.code = props.model.values.qrCode || '0'
        }
        setSignatures(data.data.resources)
      }
      load(month, compoundId)
      // eslint-disable-next-line
    }, [month, compoundId])

    if (!signatures) {
      return (
        <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
          <div className='h-[400px] relative'>
            <Spinner delay />
            <Wait />
          </div>
        </div>
      )
    }

    if (signatures.length === 0) {
      return (
        <div className={`${styles.page} ${props.readOnly ? '' : 'shadow-sm'}`}>
          {!props.readOnly && (
            <div className='p-4 bg-white flex gap-4 border-b border-gray-200'>
              <InputMonth className='flex-content' model={props.model} name='month' />
              <InputCompound
                className='flex-auto'
                model={props.model}
                name='compoundId'
              />
            </div>
          )}
          {!compoundId && (
            <div className='border-2 border-red-500 p-10 text-red-500 rounded-md m-10 text-center'>
              Dieser UKN kann nicht erstellt werden, weil keine gültige Belegung vorliegt.
            </div>
          )}
          {compoundId && (
            <div className='border-2 border-red-500 p-10 text-red-500 rounded-md m-10 text-center'>
              In der gewählten Unterkunft liegt keine Belegung vor.
            </div>
          )}
        </div>
      )
    }

    if (props.readOnly) {
      return (
        <>
          {signatures.map((s, i) => (
            <CostCoverageSignature
              key={`${i}-${compoundId}`}
              signature={s}
              instanceId={props.instanceId}
              readOnly
            />
          ))}
        </>
      )
    }

    return (
      <div className='relative'>
        <div className='p-4 bg-white flex gap-4 border-b border-gray-200'>
          <InputMonth className='flex-content' model={props.model} name='month' />
          <InputCompound className='flex-auto' model={props.model} name='compoundId' />
        </div>
        {signatures.map((s, i) => (
          <div key={`${i}-${compoundId}`} className={i > 0 ? 'mt-3' : ''}>
            <CostCoverageSignature signature={s} instanceId={props.instanceId} />
          </div>
        ))}
      </div>
    )
  },
)
