import { Collection } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { action, makeObservable, observable, reaction, when } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputSelect, InputSelectOption } from '../InputSelect'
import { Model } from 'components/Form/Model'
import { IQualification } from 'contracts/workload/interfaces/IQualification'
import { InputText } from '../InputText'

interface Props {
  model
  name: string
  disabled?: boolean
  readOnly?: boolean
}

@observer
export class InputQualification extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private readonly categoryModel: Model<{ id: string }> = new Model({
    id: '0',
  })
  private readonly disposers: Disposer[] = []
  private qualifications: Collection<IQualification>
  private categories: Collection<any>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.qualifications = new Collection(
      `/api/${context.instance.id}/workload/qualifications`,
    )
    this.categories = new Collection(
      `/api/${context.instance.id}/workload/qualificationCategories`,
    )
  }

  componentDidMount() {
    this.disposers.push(
      this.qualifications.init({ readOnly: true }),
      this.categories.init({ readOnly: true }),
    )
    this.disposers.push(
      when(
        () => !!this.qualifications.resources,
        () =>
          (this.categoryModel.values.id =
            this.qualifications.resources?.find(
              (q) => q.data?.id === this.props.model.values[this.props.name],
            )?.data?.categoryId ?? '0'),
      ),
    )
    this.disposers.push(
      reaction(
        () =>
          this.qualifications?.resources?.find(
            (q) => q.data?.id === this.props.model.values[this.props.name],
          )?.data?.categoryId,
        (categoryId) => {
          if (!categoryId) {
            return
          }
          this.categoryModel.values.id = categoryId
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private getQualificationOptions = (): InputSelectOption[] => {
    if (!this.qualifications.resources) {
      return []
    }
    const options = this.qualifications.resources
      .filter(
        (q) => q.data?.id === '0' || q.data?.categoryId === this.categoryModel.values.id,
      )
      .map((q) => ({ value: q.data!.id, label: q.data!.label }))
    options.unshift({ value: '0', label: 'Bitte wählen' })
    return options
  }

  private getCategoryOptions = (): InputSelectOption[] => {
    if (!this.categories.resources) {
      return []
    }
    const options = this.categories.resources.map((c) => ({
      value: c.data.id,
      label: c.data.label,
    }))
    options.unshift({ value: '0', label: 'Bitte wählen' })
    return options
  }

  @action onChangeCategory = () => (this.props.model.values[this.props.name] = '0')

  render() {
    if (!this.qualifications.resources || !this.categories.resources) {
      return (
        <div className='flex w-full gap-4'>
          <div className='flex-auto'>
            <InputText model={this.categoryModel} name='id' label='Kategorie' disabled />
          </div>
          <div className='relative flex-auto'>
            <InputText
              model={this.props.model}
              name={this.props.name}
              label='Bezeichnung'
              disabled
            />
          </div>
        </div>
      )
    }
    const qualification = this.qualifications?.resources.find(
      (q) => q.data?.id === this.props.model.values[this.props.name],
    )
    return (
      <div className='flex w-full gap-4'>
        <div className='flex-auto'>
          <InputSelect
            key={this.props.model.values[this.props.name]}
            model={this.categoryModel}
            name='id'
            options={this.getCategoryOptions()}
            label='Kategorie'
            disabled={
              this.context.permissions.user_hrQualifications !== 2 || this.props.disabled
            }
            readOnly={this.props.readOnly}
            onSelect={this.onChangeCategory}
          />
        </div>
        <div className='relative flex-auto'>
          <InputSelect
            model={this.props.model}
            name={this.props.name}
            options={this.getQualificationOptions()}
            label='Bezeichnung'
            disabled={
              this.context.permissions.user_hrQualifications !== 2 ||
              this.categoryModel.values.id === '0' ||
              this.props.disabled
            }
            readOnly={this.props.readOnly}
          />
          {qualification?.data?.short && (
            <span
              className='bg-indigo-400 text-white rounded-md text-xs px-1 py-0.5 ml-2 absolute'
              style={{ top: 9, right: 30 }}
            >
              {qualification.data.short}
            </span>
          )}
        </div>
      </div>
    )
  }
}
