import * as React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { RoundIcon } from 'components/RoundIcon'
import styles from './styles.module.scss'
import { IInputBlockModel } from '../../helpers/getInputBlockModel'
import { InputBlockCaption } from './components/InputBlockCaption'
import { InputBlockField } from './components/InputBlockField'

interface Props {
  model: IInputBlockModel
  disabled: boolean
  configurable?: boolean // offer admin mode to change field configs
  placeholder?: string // Placeholder text for empty sections (0 rows + multiple=true)
  onAdd?: () => void // user clicked on add button with intention to add an entry (only for multiple=true)
  onEdit?: (data: any) => void // user clicked on edit button with intention to edit a row (only for disabled=true)
  onDelete?: (data: any) => void // user clicked on delete button with intention to delete a row (only for multiple=true)
  setMode: (mode: 'config' | 'data') => void
}

export const selectOptions = [
  { value: 'text', label: 'Text' },
  { value: 'date', label: 'Datum' },
  { value: 'select', label: 'Auswahl' },
  { value: 'dropdown', label: 'Eigene Auswahl' },
  { value: 'file', label: 'Dokument' },
]

export const colClasses = [
  '',
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
  'grid-cols-7',
  'grid-cols-8',
]

export const colSpanClasses = [
  '',
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
  'col-span-7',
  'col-span-8',
]

export const InputBlockData: React.FC<Props> = observer((props) => {
  const config = props.model.config
  const rows = props.model.data.rows

  return (
    <div className='bg-white shadow-md rounded-md p-4'>
      <InputBlockCaption
        setMode={props.setMode}
        model={props.model}
        disabled={props.disabled}
        configurable={props.configurable}
        onAdd={props.onAdd}
        onEdit={props.onEdit}
      />
      <div className='mt-2'>
        {config.fields.length === 0 && (
          <div className='col-span-full text-gray-500 mx-auto my-4'>
            Für diesen Abschnitt wurden noch keine Felder angelegt.
          </div>
        )}
        {config.multiple && rows.length === 0 && (
          <div className='col-span-full text-gray-500 mx-auto my-4'>
            {props.placeholder || 'Dieser Abschnitt enthält noch keine Einträge.'}
          </div>
        )}
        {config.fields.length > 0 && (
          <div>
            {rows.map((row, index) => (
              <div
                id={row.id}
                key={row.values.id}
                className={index % 2 === 1 ? 'bg-gray-200 -mx-4' : undefined}
              >
                {index !== 0 && index % 2 === 1 && (
                  <div className={`${styles.white} h-[20px] mt-2`} />
                )}
                <div
                  className={`grid ${colClasses[config.cols]} ${
                    index % 2 === 1 ? 'px-4' : ''
                  } gap-4 py-4 relative group/row`}
                >
                  {config.fields.map((field) => (
                    <InputBlockField
                      key={field.key}
                      field={field}
                      model={row}
                      disabled={props.disabled}
                    />
                  ))}
                  <div
                    className={`absolute top-1 ${
                      index % 2 === 1 ? 'right-2' : '-right-2'
                    } hidden group-hover/row:block z-1`}
                  >
                    {props.onEdit && config.multiple && (
                      <RoundIcon
                        icon='fa fa-edit'
                        onClick={() => props.onEdit!(toJS(row.values))}
                      />
                    )}
                    {props.onDelete && config.multiple && (
                      <RoundIcon
                        classNameContainer='ml-2'
                        color='danger'
                        icon='fa fa-trash'
                        onClick={() => props.onDelete!(toJS(row.values))}
                      />
                    )}
                  </div>
                </div>
                {index % 2 === 1 && <div className={`${styles.gray} h-[20px] mb-4`} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
})
