import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import styles from '../../styles.module.scss'
import { Collection, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { observer } from 'mobx-react'
import { Spinner } from 'components/Spinner'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import { IEmployeeRecordBlock } from 'contracts/employees/interfaces/IEmployeeRecordBlock'
import { NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { EmployeeRecordBlock } from './components/EmployeeRecordBlock'

interface Props {
  employee: IEmployeeSearchResult
}

@observer
export class EmployeeDialogRecordTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly configs: Collection<IInputBlockConfig>
  private readonly blocks: Collection<IEmployeeRecordBlock>
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.configs = new Collection(`/api/${context.instance.id}/inputBlockConfigs`)
    this.blocks = new Collection(
      `/api/${context.instance.id}/employees/${props.employee.id}/employeeRecordBlocks`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.configs.init({ readOnly: true }))
    this.disposers.push(this.blocks.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  /*@action private getOptions = () => {
    for (const block of this.employeeFileBlocks.resources ?? []) {
      if (!block.data) {
        continue
      }
      if (
        block.data.config.multiple &&
        Array.isArray(block.data.details) &&
        block.data.details.length === 0
      ) {
        if (!this.options.find((o) => o.value === block.data?.id)) {
          this.options.push({
            label: block.data.config.label,
            value: block.data.id,
          })
        }
      } else if (
        block.data.config.multiple &&
        Array.isArray(block.data.details) &&
        block.data.details.length > 0
      ) {
        if (this.options.find((o) => o.value === block.data?.id)) {
          this.options.splice(
            this.options.findIndex((o) => o.value === block.data?.id),
            1,
          )
        }
      }
    }
  }*/

  render() {
    const employee = this.props.employee
    if (
      this.configs.error?.id === RequestPendingError.id ||
      this.blocks.error?.id === RequestPendingError.id
    ) {
      return <Spinner />
    }
    if (this.blocks.error?.id === NotAuthorizedError.id) {
      return <Forbidden />
    }
    if (!this.configs.resources || !this.blocks.resources) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col'>
          <LoadingError title='Mitarbeiterakte konnte nicht geladen werden.' />
        </div>
      )
    }

    return (
      <div>
        {/* User image */}
        <img
          src={getUserImageSrc(
            this.context.instance.id,
            employee.imageId, // user.imageId,
            employee.sex, // user.sex,
            'portrait',
          )}
          className={styles.portrait}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='flex flex-col gap-6 py-6'>
          {this.configs.resources?.map((config) => {
            if (config.data?.scope !== 'employee') {
              return null
            }
            const block = hermes.getFromStore<IEmployeeRecordBlock>(
              `/api/${this.context.instance.id}/employees/${employee.id}/employeeRecordBlocks/${config.id}`,
              false,
            )
            if (!block?.data) {
              return null
            }
            return (
              <div
                key={config.id}
                className={config.id === 'employee-name' ? 'pr-[238px]' : ''}
              >
                <EmployeeRecordBlock
                  config={config.data}
                  data={block.data}
                  employee={this.props.employee}
                />
              </div>
            )
          })}
          {/*<div className='pl-6'>
            <EmployeeBlockDropdownButton
              options={this.options}
              employeeFileBlocks={this.employeeFileBlocks}
              employee={this.props.employee}
            />
          </div>*/}
        </div>
      </div>
    )
  }
}
