import * as React from 'react'
import { EmployeeFilter } from './components/EmployeeFilter'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getSavedEmployeeSearchResultsFilter } from './helpers/getSavedEmployeeSearchResultsFilter'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { action, makeObservable, observable, reaction, toJS } from 'mobx'
import { storage } from 'services/storage'
//import { EmployeeDialog } from './components/EmployeeDialog'
import { Location, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
//import { ReportDialog } from './components/ReportDialog'
//import { KanzleiDialog } from './components/KanzleiDialog'
import { Collection } from '@byll/hermes'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { isMonth } from 'contracts/general/helpers/isMonth'
import { dayjs } from 'helpers/dayjs'
import { EmployeeList } from './components/EmployeeList'
import { IEmployeeSearchResultsMetadata } from 'contracts/employees/interfaces/IEmployeeSearchResultsMetadata'
import { EmployeeDialog } from './components/EmployeeDialog'

interface Props {
  location: Location
  navigate: (path: string) => void
}

@observer
export class Employees extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private readonly reports: Collection<IEmployeeReportDefinition>
  @observable private readonly selected = new Set<string>() // Employee IDs
  @observable private readonly filter: IEmployeeSearchResultsFilter
  @observable.ref private view: {
    month: string
    employees: Collection<
      IEmployeeSearchResult,
      IEmployeeSearchResultsMetadata,
      IEmployeeSearchResultsFilter
    >
  }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.reports = new Collection(
      `/api/${context.instance.id}/workload/reportDefinitions`,
    )
    this.filter = getSavedEmployeeSearchResultsFilter(context.user)
    const tmp = storage.get(`employees.admin.month.${context.user.id}`)
    const month = isMonth(tmp) ? tmp : dayjs().format('YYYY-MM')
    this.view = {
      month,
      employees: new Collection(
        `/api/${context.instance.id}/workload/month/${month}/employeeSearchResults`,
        this.filter,
      ),
    }
  }

  componentDidMount() {
    this.view.employees.init({ readOnly: true, observeQuery: true })
    this.disposers.push(() => this.view.employees.dispose()) // Dispose current collection (collection can change, so don't pass disposer directly)
    this.disposers.push(this.reports.init({ readOnly: true }))
    this.disposers.push(
      reaction(
        () => toJS(this.filter),
        (values) => {
          storage.set(`employees.admin.${this.context.user.id}`, values)
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private setMonth = (month: string) => {
    if (this.view.month === month) {
      return
    }
    this.view.employees.dispose()
    this.view = {
      month,
      employees: new Collection(
        `/api/${this.context.instance.id}/workload/month/${month}/employeeSearchResults`,
        this.filter,
      ),
    }
    this.view.employees.init({ readOnly: true, observeQuery: true })
    storage.set(`employees.admin.month.${this.context.user.id}`, month)
  }

  /*private closeReportDialog = () => {
    this.props.navigate('/employees')
  }*/

  render() {
    const match = this.props.location.pathname
      .replace(/\/$/, '')
      .toLowerCase()
      .match(/^\/employees\/(\d+)\/([a-z-]+)$/)
    const employeeId = match ? match[1] : null
    return (
      <div className='pt-14 bg-gray-100 min-h-full flex flex-col'>
        <EmployeeFilter
          month={this.view.month}
          setMonth={this.setMonth}
          selected={this.selected}
          filter={this.filter}
          navigate={this.props.navigate}
          reports={this.reports}
          employees={this.view.employees}
        />
        <div className='flex-auto relative overflow-hidden'>
          <div
            className={`z-1 transition-right bg-white shadow-md absolute top-0 bottom-0 left-0 ${
              employeeId ? 'right-[700px]' : 'right-0'
            }`}
          >
            <EmployeeList
              employees={this.view.employees}
              selected={this.selected}
              employeeId={employeeId}
            />
          </div>
          <div
            className={`z-1 md:z-0 overflow-auto transition-right bg-gray-100 absolute top-0 bottom-0 w-full md:w-[700px] ${
              employeeId ? 'right-0' : '-right-[1000px]'
            }`}
          >
            {employeeId && (
              <EmployeeDialog
                key={employeeId}
                month={this.view.month}
                employeeId={employeeId}
              />
            )}
          </div>
        </div>
        <Routes>
          {/*<Route
            path='reports/:id'
            element={
              <RenderReportDialog
                loadDefinitionOptions={this.loadDefinitionOptions}
                onClose={this.closeReportDialog}
              />
            }
          />
          <Route
            path='kanzlei'
            element={
              <RenderKanzleiReport
                key={`${this.employees.query.month}-${this.employees.query.companyId}`}
                companyId={this.employees.query.companyId || null}
                month={this.employees.query.month}
                onClose={this.closeReportDialog}
              />
            }
          />*/}
        </Routes>
      </div>
    )
  }
}

/*export const RenderReportDialog: React.FC<{
  onClose: () => void
  loadDefinitionOptions: () => void
}> = observer((props) => {
  const params = useParams()

  return (
    <ReportDialog
      loadDefinitionOptions={props.loadDefinitionOptions}
      definitionId={params.id || ''}
      onClose={props.onClose}
    />
  )
})

export const RenderKanzleiReport: React.FC<{
  companyId: string | null
  month: string
  onClose: () => void
}> = observer((props) => {
  return (
    <KanzleiDialog
      companyId={props.companyId}
      month={props.month}
      onClose={props.onClose}
    />
  )
})*/
