import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { IUser } from 'contracts/users/interfaces/IUser'
import { isPlainObject } from 'helpers/isPlainObject'
import { storage } from 'services/storage'

export function getSavedEmployeeSearchResultsFilter(
  user: IUser,
): IEmployeeSearchResultsFilter {
  let settings: any = storage.get(`employees.admin.${user.id}`)
  if (!isPlainObject(settings)) {
    settings = {}
  }
  const filter: IEmployeeSearchResultsFilter = {
    employed: settings.employed === 'yes' ? 'yes' : '',
    companyId: null,
    scope: '',
    page: '0,50',
  }
  if (isIntegerString(settings.companyId)) {
    filter.companyId = settings.companyId
  }
  return filter
}
