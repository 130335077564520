import { RoundIcon } from 'components/RoundIcon'
import { toJS } from 'mobx'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { IInputBlockModel } from '../../../helpers/getInputBlockModel'

interface Props {
  setMode: (mode: 'config' | 'data') => void
  model: IInputBlockModel
  disabled?: boolean // true: disabled + live updates of models. false: editable + no live updates of data. Only displays initial data so that user changes don't get overwritten.
  configurable?: boolean // offer admin mode to change field configs
  onAdd?: () => void // user clicked on add button with intention to add an entry (only for multiple=true)
  onEdit?: (data: any) => void // user clicked on edit button with intention to edit a row (only for disabled=true)
}

export const InputBlockCaption: React.FC<Props> = ({
  setMode,
  model,
  disabled,
  configurable,
  onEdit,
  onAdd,
}) => {
  const context = React.useContext(AppContext)
  const config = model.config
  const rows = model.data.rows

  return (
    <div className='flex'>
      <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
        {config.label}
      </div>
      <div className='flex-content'>
        {onEdit && disabled && !config.multiple && config.fields.length > 0 && (
          <RoundIcon icon='fa fa-edit' onClick={() => onEdit!(toJS(rows[0].values))} />
        )}
        {onAdd && config.multiple && config.fields.length > 0 && (
          <RoundIcon icon='fa fa-plus' onClick={() => onAdd!()} />
        )}
        {context.permissions.admin_manageCustomFields && configurable && (
          <RoundIcon
            classNameContainer='ml-2'
            icon='fa fa-cog'
            onClick={() => setMode('config')}
          />
        )}
      </div>
    </div>
  )
}
