import { z } from 'zod'
import { IInputBlockConfig } from '../interfaces/IInputBlockConfig'

export function getInputBlockRowValidator(config: IInputBlockConfig) {
  const model: any = {}
  for (const field of config.fields) {
    if (field.input === 'required') {
      model[field.key] = z.string().min(1)
    }
  }
  if (Object.keys(model).length === 0) {
    return
  }
  return z.object(model)
}
