import { observer } from 'mobx-react'
import * as React from 'react'
import { arrayMoveImmutable } from 'array-move'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlockField'
import { colClasses } from '../../InputBlockData'
import { Button } from 'components/Form/components/Button'
import { EditField } from './EditField'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import * as uuid from 'uuid'

interface Props {
  id: string
  config: IInputBlockConfig
}

@observer
export class SortableInputGrid extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMoveImmutable(this.props.config.fields, oldIndex, newIndex)
    runInAction(() => (this.props.config.fields = newArray))
  }

  @action private deleteField = (key: string) => {
    this.props.config.fields = this.props.config.fields.filter((f) => f.key !== key)
  }

  @action private addField = () => {
    this.props.config.fields.push({
      key: `_new_field_${uuid.v4()}`,
      label: 'Neu',
      type: 'text',
      colspan: 1,
      config: 'mutable',
      input: 'optional',
    })
  }

  render() {
    return (
      <SortableGrid
        id={this.props.id}
        key={this.props.config.fields.length}
        config={this.props.config}
        cols={this.props.config.cols}
        fields={this.props.config.fields}
        axis='xy'
        distance={5}
        onSortEnd={this.onSortEnd}
        addField={this.addField}
        deleteField={this.deleteField}
      />
    )
  }
}

const SortableGrid = observer(
  SortableContainer(({ id, cols, fields, config, addField, deleteField }) => {
    return (
      <div className={`my-4 grid ${colClasses[cols]} gap-4`}>
        {fields.map((field, index) => (
          <SortableItem
            id={id}
            key={field.key}
            index={index}
            field={field}
            config={config}
            deleteField={deleteField}
          />
        ))}
        <Button onClick={addField}>
          <span>
            <i className='fa fa-plus' />
          </span>
        </Button>
      </div>
    )
  }),
)

const SortableItem = observer(
  SortableElement(({ id, field, config, deleteField }) => {
    return <EditField id={id} field={field} config={config} onDelete={deleteField} />
  }),
)
