import * as React from 'react'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { IInputBlockConfig } from 'contracts/inputBlock/interfaces/IInputBlockConfig'
import { IInputBlockData } from 'contracts/inputBlock/interfaces/IInputBlockData'
import { action, makeObservable, observable, reaction } from 'mobx'
import { getInputBlockModel, IInputBlockModel } from './helpers/getInputBlockModel'
import * as uuid from 'uuid'
import { InputBlockConfig } from './components/InputBlockConfig'
import { InputBlockData } from './components/InputBlockData'

const models = new Map<string, Model<any>[]>() // uuid -> array of models

interface Props {
  config: IInputBlockConfig
  data: IInputBlockData
  disabled?: boolean // true: disabled + live updates of models. false: editable + no live updates of data. Only displays initial data so that user changes don't get overwritten.
  configurable?: boolean // offer admin mode to change field configs
  placeholder?: string // Placeholder text for empty sections (0 rows + multiple=true)
  id?: string // Should be a UUID. Only for disabled != true Can be used to fetch the data via getInputBlockModels<D>(id) -> Model<D>[]
  onAdd?: () => void // user clicked on add button with intention to add an entry (only for multiple=true)
  onEdit?: (data: any) => void // user clicked on edit button with intention to edit a row (only for disabled=true)
  onDelete?: (data: any) => void // user clicked on delete button with intention to delete a row (only for multiple=true)
}

@observer
export class InputBlock extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly disabled: boolean
  @observable private model: IInputBlockModel
  @observable private mode: 'config' | 'data' = 'data' // data => display data. Config => Display field config.
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    this.id = props.id || uuid.v4()
    this.disabled = props.disabled || false
    this.model = getInputBlockModel(props.data, props.config)
    makeObservable(this)
  }

  componentDidMount(): void {
    if (this.disabled) {
      // Disabled mode: Create a new validated model if either the config or the data changes
      // This will display live updates of the data on every change. Unfortunately we cannot
      // do this for non-disabled mode because we would overwrite user changes on every server
      // side data change update. But for disabled mode this is fine because the user cannot
      // change the data anyway so nothing gets overwritten.
      this.disposers.push(
        reaction(
          () => `${this.props.data.version}.${this.props.config.version}`,
          () => {
            if (
              this.props.data.version !== this.model.data.version ||
              this.props.config.version !== this.model.config.version
            ) {
              this.model = getInputBlockModel(this.props.data, this.props.config)
            }
          },
          { fireImmediately: true },
        ),
      )
    } else {
      models.set(this.id, this.model.data.rows)
      this.disposers.push(() => models.delete(this.id))
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action private setMode = (mode: 'config' | 'data') => {
    this.mode = mode
  }

  render() {
    return (
      <div className='bg-gray-100 px-6'>
        {this.mode === 'data' && (
          <InputBlockData
            key={`${this.model.data.version}.${this.model.config.version}`}
            model={this.model}
            disabled={this.disabled}
            configurable={this.props.configurable}
            placeholder={this.props.placeholder}
            onAdd={this.props.onAdd}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            setMode={this.setMode}
          />
        )}

        {this.mode === 'config' && <InputBlockConfig id={this.id} model={this.model} />}
      </div>
    )
  }
}

export function getInputBlockModels<D>(id: string): Model<D>[] | null {
  return models.get(id) || null
}
