import { Collection } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { InputUser } from 'components/Form/components/InputUser'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
// import { SendDialog } from './components/SendDialog'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'
import { InputCompany } from 'components/Form/components/InputCompany'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { DownloadDropdownButton } from './components/ReportDialog/components/DownloadDropdownButton'
import { InputButtonGroup } from 'components/Form/components/InputButtonGroup'

const employmentOptions: InputSelectOption[] = [
  { value: '', label: 'Alle' },
  { value: 'yes', label: 'Aktive' },
]

interface Props {
  month: string
  setMonth: (month: string) => void
  selected: Set<string> // Employee IDs
  filter: IEmployeeSearchResultsFilter
  navigate: (path: string) => void
  reports: Collection<IEmployeeReportDefinition>
  employees: Collection<IEmployeeSearchResult>
}

@observer
export class EmployeeFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeSearchResultsFilter>
  private readonly monthModel: Model<{ month: string }>
  private readonly userModel: Model<{ userId: string | null }>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.filter)
    this.monthModel = new Model({ month: props.month })
    this.userModel = new Model({ userId: null })
  }

  componentDidMount(): void {}

  /*private load = async () => {
    const companies = await hermes.indexOnceNew<ICompany>(
      `/api/${this.context.instance.id}/companies`,
    )
    runInAction(() => {
      this.companies = [
        { value: null, label: 'Kein Vertrag' },
        ...companies.map(
          (company: ICompany): InputSelectOption => ({
            value: company.id,
            label: company.name,
          }),
        ),
      ]
    })
  }*/

  private navigateToShifts = () => {
    this.props.navigate(`/shifts`)
  }

  @action
  private navigateToUser = (id: string | null) => {
    if (!id) {
      return
    }
    this.userModel.values.userId = null
    this.props.navigate(`/employees/${id}/overview`)
    ;(document.activeElement as any)?.blur?.()
  }

  private openSendDialog = () => {
    /*if (!this.props.employees.resources) {
      return
    }
    const employees = this.props.employees.resources.filter((e) => {
      const value = this.props.employees.rerender.get(e.id)
      return value && value > 0
    })
    const promise = box.custom(
      <SendDialog
        employees={employees}
        month={this.props.filterModel.values.month}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'md' },
    )*/
  }

  @action
  private checkAllEmployees = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!this.props.employees.resources) {
      return
    }
    for (const employee of this.props.employees.resources) {
      if (e.target.checked) {
        this.props.selected.add(employee.id) // check
      } else {
        this.props.selected.delete(employee.id) // uncheck
      }
    }
  }

  render() {
    const someUnchecked =
      this.props.employees.resources?.some((r) => !this.props.selected.has(r.id)) ?? true
    return (
      <div className='relative flex-content grid grid-cols-1 sm:grid-cols-[1fr_220px] md:grid-cols-[220px_220px_1fr] xl:grid-cols-[14px_220px_220px_220px_220px_1fr_298px] gap-4 p-6 bg-white shadow border-b border-gray-300 z-2'>
        <div className='pt-[7px] hidden xl:block'>
          <input
            className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded ${
              !this.props.employees.resources?.length ? 'bg-gray-100' : ''
            }`}
            type='checkbox'
            checked={!someUnchecked && !!this.props.employees.resources?.length}
            disabled={!this.props.employees.resources?.length}
            onChange={this.checkAllEmployees}
          />
        </div>
        <div className='whitespace-nowrap flex'>
          <Button
            className='flex-auto'
            color='secondary'
            outline
            style={{ borderRadius: '6px 0 0 6px' }}
            onClick={this.navigateToShifts}
          >
            Schichten
          </Button>
          <Button
            className='flex-auto'
            color='primary'
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            Mitarbeiter
          </Button>
        </div>

        <InputMonth model={this.monthModel} name='month' onSelect={this.props.setMonth} />
        <InputCompany
          emptyText='Alle Firmen'
          model={this.model}
          name='companyId'
          className='col-span-1 sm:col-span-2 md:col-span-1'
        />
        <InputButtonGroup
          model={this.model}
          name='employed'
          options={employmentOptions}
          className='col-span-1 sm:col-span-2 xl:col-span-1'
        />

        <div className='hidden xl:block' />

        <div className='flex col-span-1 sm:col-span-2 md:col-span-1'>
          <InputUser
            model={this.userModel}
            name='userId'
            className='flex-auto mr-4'
            onChoose={this.navigateToUser}
            placeholder='Suche'
          />
          {this.context.permissions.workplan_sendPlans && (
            <Button
              color='secondary'
              className='w-[38px] text-center bg-white'
              outline
              style={{
                borderRadius: '6px 0 0 6px',
                borderRight: 'none',
                padding: '8px 0',
              }}
              onClick={this.openSendDialog}
            >
              <i className='fas fa-paper-plane' />
            </Button>
          )}
          <DownloadDropdownButton
            month={this.props.month}
            reports={this.props.reports}
            employees={this.props.employees}
            filter={this.props.filter}
            selected={this.props.selected}
            navigate={this.props.navigate}
          />
        </div>
      </div>
    )
  }
}
