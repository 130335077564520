import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, reaction } from 'mobx'
import { IEmployeeQualification } from 'contracts/employees/interfaces/IEmployeeQualification'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { InputDate } from 'components/Form/components/InputDate'
import { InputDocument } from 'components/Form/components/InputDocument'
import { InputText } from 'components/Form/components/InputText'
import { Model } from 'components/Form/Model'
import { InputQualification } from 'components/Form/components/InputQualification'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IQualification } from 'contracts/workload/interfaces/IQualification'

interface Props {
  qualification: IEmployeeQualification
  rQualification: IQualification
  onEdit: (qualification: IEmployeeQualification) => void
  onDelete: (id: string) => void
}

@observer
export class EmployeeQualification extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable showAll: boolean = false
  @observable private model: Model<IEmployeeQualification>
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.qualification)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.props.qualification,
        (qual) => (this.model = new Model(qual)),
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  onEdit = () => this.props.onEdit(this.props.qualification)

  onDelete = () => this.props.onDelete(this.props.qualification.id)

  @action toggleShowAll = () => (this.showAll = !this.showAll)

  render() {
    if (!this.showAll) {
      return (
        <>
          <div className='flex mb-2 items-center group min-h-[30px]'>
            <span
              className='mr-2'
              style={{ cursor: 'pointer' }}
              onClick={this.toggleShowAll}
            >
              {!this.showAll ? (
                <i className='fa fa-caret-right'></i>
              ) : (
                <i className='fa fa-caret-down'></i>
              )}
            </span>
            <span className='ml-2'>{this.props.rQualification.label}</span>
            {this.props.rQualification.short && (
              <span className='bg-indigo-400 text-white rounded-md text-xs px-1 py-0.5 ml-2'>
                {this.props.rQualification.short}
              </span>
            )}
            {this.context.permissions.user_hrQualifications === 2 && (
              <div className='ml-auto group-hover:block hidden z-1'>
                <RoundIcon icon='fa fa-edit' onClick={this.onEdit} />
                <RoundIcon
                  classNameContainer='ml-2'
                  icon='fa fa-trash'
                  color='danger'
                  onClick={this.onDelete}
                />
              </div>
            )}
          </div>
          <div
            style={{
              height: 7,
              borderBottom: '1px solid #dddddd',
              marginBottom: 22,
            }}
          />
        </>
      )
    } else {
      return (
        <div className='group relative'>
          <div className='flex mb-2 items-center min-h-[30px]'>
            <span
              className={`mr-2 ${!this.showAll ? 'mb-2' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={this.toggleShowAll}
            >
              {!this.showAll ? (
                <i className='fa fa-caret-right'></i>
              ) : (
                <i className='fa fa-caret-down'></i>
              )}
            </span>
            <span className='ml-2'>{this.props.rQualification.label}</span>
            {this.props.rQualification.short && (
              <span className='bg-indigo-400 text-white rounded-md text-xs px-1 py-0.5 ml-2'>
                {this.props.rQualification.short}
              </span>
            )}
            {this.context.permissions.user_hrQualifications === 2 && (
              <div className='ml-auto flex-content group-hover:block hidden z-1'>
                <RoundIcon icon='fa fa-edit' onClick={this.onEdit} />
                <RoundIcon
                  classNameContainer='ml-2'
                  icon='fa fa-trash'
                  color='danger'
                  onClick={this.onDelete}
                />
              </div>
            )}
          </div>

          {/* Line 1 */}
          <div className='flex mb-3 mt-3'>
            <InputQualification
              key={this.model.values.qualificationId}
              model={this.model}
              name='qualificationId'
              disabled
            />
          </div>

          {/* Line 2 */}
          <div className='flex mb-3'>
            <div style={{ flex: '0 0 115px' }}>
              <InputDate
                model={this.model}
                name='trainingStartDate'
                label='Ausbildung von'
                disabled
              />
            </div>
            <div style={{ flex: '0 0 1rem' }} />
            <div style={{ flex: '0 0 115px' }}>
              <InputDate model={this.model} name='trainingEndDate' label='bis' disabled />
            </div>
            <div style={{ flex: '0 0 1rem' }} />
            <div style={{ flex: '1 1 auto' }}>
              <InputDocument
                label='Anhang'
                model={this.model}
                name='documentId'
                scope='employee'
                disabled
              />
            </div>
          </div>

          {/* Line 3 */}
          <div className='flex mb-3'>
            <div style={{ flex: '0 0 115px' }}>
              <InputDate
                model={this.model}
                name='startDate'
                label='Gültig von'
                disabled
              />
            </div>
            <div style={{ flex: '0 0 1rem' }} />
            <div style={{ flex: '0 0 115px' }}>
              <InputDate model={this.model} name='endDate' label='bis' disabled />
            </div>
            <div style={{ flex: '0 0 1rem' }} />
            <div style={{ flex: '1 1 auto' }}>
              <InputText label='Notizen' model={this.model} name='notes' disabled />
            </div>
          </div>

          {/* Spacer & border */}
          <div
            style={{
              height: 7,
              borderBottom: '1px solid #dddddd',
              marginBottom: 22,
            }}
          />
        </div>
      )
    }
  }
}
